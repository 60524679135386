var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Jz=function(){},Kz=function(a){return $CLJS.J.g($CLJS.yy,a).value},zda=function(a){var b=Kz(a);return $CLJS.n(function(){var c=Kz($CLJS.Py);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.uz:$CLJS.n(function(){var c=Kz($CLJS.Oy);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Ez:$CLJS.n(function(){var c=Kz($CLJS.My);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.xz:$CLJS.n(function(){var c=Kz($CLJS.Gy);return $CLJS.Kk.g?
$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Dz:$CLJS.n(function(){var c=Kz($CLJS.Ny);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Fz:$CLJS.n(function(){var c=Kz($CLJS.Jy);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Az:$CLJS.n(function(){var c=Kz($CLJS.Dy);return $CLJS.Kk.g?$CLJS.Kk.g(c,b):$CLJS.Kk.call(null,c,b)}())?$CLJS.Bz:$CLJS.Cz},Lz=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},Mz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Iz,c))].join(""),$CLJS.H(["color:black"]))],null)},Nz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Iz,$CLJS.Gz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.Iz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},Ada=function(a){function b(c,d){return d>=c}a=Kz(a);if(b(Kz($CLJS.Py),a))return"error";if(b(Kz($CLJS.Oy),a))return"warn";if(b(Kz($CLJS.My),a))return"info";b(Kz($CLJS.Gy),a);return"log"},Oz=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.Ey),e=$CLJS.J.g(c,$CLJS.Pt);b=$CLJS.J.g(c,$CLJS.Qy);e=Ada(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Se.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Pz=function Pz(a,b){for(;;){if($CLJS.E.g($CLJS.tz,b))return Mz(a,", ",$CLJS.Cz);if($CLJS.E.g($CLJS.vz,b))return Lz(a);if(b instanceof $CLJS.M)return Mz(a,b,$CLJS.xz);if(b instanceof $CLJS.r)return Mz(a,b,$CLJS.Dz);if("string"===typeof b)return Mz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.wz);if($CLJS.zf(b)){var d=Lz(function(){var f=a,k=$CLJS.Eb(b);return Pz.g?Pz.g(f,k):Pz.call(null,f,k)}()),e=$CLJS.Fb(b);
return Pz.g?Pz.g(d,e):Pz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Hg)return d=a,d=Mz(d,"{",$CLJS.Hz),d=$CLJS.fb(Pz,d,$CLJS.ff($CLJS.tz,b)),Mz(d,"}",$CLJS.Hz);if($CLJS.xd(b))return d=a,d=Mz(d,["#",$CLJS.p.h(function(){var f=$CLJS.ab(b),k=f.name;return $CLJS.td(k)?$CLJS.Mh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.yz),d=Mz(d,"{",$CLJS.Hz),d=$CLJS.fb(Pz,d,$CLJS.ff($CLJS.tz,b)),Mz(d,"}",$CLJS.Hz);if($CLJS.vd(b))return d=a,d=Mz(d,"#{",$CLJS.Hz),d=$CLJS.fb(Pz,d,$CLJS.ff($CLJS.vz,b)),Mz(d,
"}",$CLJS.Hz);if($CLJS.zd(b))return d=a,d=Mz(d,"[",$CLJS.Hz),d=$CLJS.fb(Pz,d,$CLJS.ff($CLJS.vz,b)),Mz(d,"]",$CLJS.Hz);if(b instanceof $CLJS.Rf)d=Mz(a,"#queue ",$CLJS.yz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=Mz(d,"(",$CLJS.yz),d=$CLJS.fb(Pz,d,$CLJS.ff($CLJS.vz,b)),Mz(d,")",$CLJS.yz);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.$a(Jz,b)):$CLJS.$a(Jz,b))d=Mz(a,"#atom ",$CLJS.yz),e=$CLJS.q(b),a=d,b=e;else if($CLJS.ei(b))d=Mz(a,"#uuid ",$CLJS.yz),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Xa(b))d=Mz(a,"#js ",$CLJS.yz),e=$CLJS.fb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.zh.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ua(b))d=Mz(a,"#js ",$CLJS.yz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else return Mz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.zz)}}};$CLJS.Bda=Oz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Pt);var b=$CLJS.J.g(a,$CLJS.Ey),c=$CLJS.J.g(a,$CLJS.Pi);$CLJS.J.g(a,$CLJS.Qy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.Cda=Oz(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Pt);a=$CLJS.J.g(b,$CLJS.Ey);var d=$CLJS.J.g(b,$CLJS.Pi);$CLJS.J.g(b,$CLJS.Qy);b=zda(c);d=Pz(Lz(Nz(Nz(Nz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Cf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ee(a,d)});
$CLJS.Dda=Oz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Pt);var b=$CLJS.J.g(a,$CLJS.Ey),c=$CLJS.J.g(a,$CLJS.Pi);$CLJS.J.g(a,$CLJS.Qy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Mh.l($CLJS.H([c]))],null)});