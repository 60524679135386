var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var ufa,vfa,wfa,xfa,yfa,zfa,Afa,Bfa,Cfa;ufa=function(a,b){a.sort(b||$CLJS.Da)};vfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;ufa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.aH=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.bH=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);vfa(c,$CLJS.aH(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.cH=new $CLJS.M(null,"second","second",-444702010);$CLJS.dH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.eH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.fH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.gH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);wfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
xfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.hH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.iH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
yfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);zfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);Afa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
Bfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.jH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.kH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.lH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Cfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var mH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Fi,$CLJS.Fj,$CLJS.Ej,$CLJS.pi,$CLJS.lk,$CLJS.zi,$CLJS.ji,$CLJS.gH],null),nH=$CLJS.fh(mH),Dfa,Efa,Ffa;$CLJS.X(Afa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid date extraction unit"],null)],null),nH));var oH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.eu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null),pH=$CLJS.fh(oH);
$CLJS.X(zfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid date truncation unit"],null)],null),pH));$CLJS.qH=$CLJS.eg.j($CLJS.Cf,$CLJS.il.o(),$CLJS.gf.g(oH,mH));Dfa=$CLJS.fh($CLJS.qH);$CLJS.X($CLJS.iH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid date bucketing unit"],null)],null),Dfa));var rH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dH,$CLJS.hj,$CLJS.vi],null),sH=$CLJS.fh(rH);
$CLJS.X(yfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid time extraction unit"],null)],null),sH));var tH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fH,$CLJS.cH,$CLJS.Ti,$CLJS.qk],null),uH=$CLJS.fh(tH);$CLJS.X(Cfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid time truncation unit"],null)],null),uH));$CLJS.vH=$CLJS.eg.j($CLJS.Cf,$CLJS.il.o(),$CLJS.gf.g(tH,rH));Efa=$CLJS.fh($CLJS.vH);
$CLJS.X(xfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid time bucketing unit"],null)],null),Efa));$CLJS.wH=$CLJS.eg.j($CLJS.Cf,$CLJS.il.o(),$CLJS.gf.l(tH,oH,$CLJS.H([rH,mH])));Ffa=$CLJS.fh($CLJS.wH);$CLJS.X($CLJS.eH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid datetime bucketing unit"],null)],null),$CLJS.wH));var Gfa=$CLJS.be.g(Ffa,$CLJS.ci);
$CLJS.X($CLJS.kH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid temporal bucketing unit"],null)],null),Gfa));$CLJS.xH=$CLJS.fu.g(pH,uH);$CLJS.X($CLJS.hH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid datetime truncation unit"],null)],null),$CLJS.xH));$CLJS.yH=$CLJS.fu.g(nH,sH);$CLJS.X(wfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid datetime extraction unit"],null)],null),$CLJS.yH));
var zH=$CLJS.be.g(pH,$CLJS.ji);$CLJS.X($CLJS.lH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid date interval unit"],null)],null),zH));$CLJS.X($CLJS.lE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid time interval unit"],null)],null),uH));var Hfa=$CLJS.fu.g(zH,uH);$CLJS.X($CLJS.mE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid datetime interval unit"],null)],null),Hfa));
$CLJS.X(Bfa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.jH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,$CLJS.kH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null));