var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var g2,boa,m2,n2,p2,coa,doa,eoa,foa,goa,ioa,joa,koa,hoa,s2,t2;g2=function(a,b){var c=$CLJS.Gd,d=f2;for(a=$CLJS.y(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.A(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};$CLJS.h2=function(a,b,c){return null==c||$CLJS.Hd(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.i2=function(a,b){return $CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.GE,$CLJS.p.h($CLJS.QE())],null)],null),$CLJS.hf.h($CLJS.yW),b)};
$CLJS.j2=function(a,b){a=$CLJS.LW(a,b);return 0<a?a-1:null};$CLJS.k2=function(a,b){if($CLJS.y(b)){if($CLJS.E.g($CLJS.D(b),1))return $CLJS.A(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.E.g($CLJS.D(b),2)?[$CLJS.p.h($CLJS.A(b)),a,$CLJS.p.h($CLJS.hd(b))].join(""):[$CLJS.It(", ",$CLJS.Ft(b)),",",a,$CLJS.p.h($CLJS.id(b))].join("")}return null};
$CLJS.l2=function(a,b,c,d){a=$CLJS.KW(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.MW(a,b);e=!($CLJS.y($CLJS.eG.h(e))||$CLJS.y($CLJS.dG.h(e)));c=$CLJS.XW.l(a,b,$CLJS.Sk,$CLJS.H([c,function(f){return $CLJS.be.g($CLJS.Df(f),$CLJS.yW.h(d))}]));return e?$CLJS.Sk.N((0,$CLJS.XW)(c,b,function(f){return $CLJS.aX($CLJS.Hk.l(f,$CLJS.VR,$CLJS.H([$CLJS.ZE])),$CLJS.KN,function(k){return $CLJS.Rk.g(function(l){return $CLJS.Hk.g(l,$CLJS.ZE)},k)})}),$CLJS.tL,$CLJS.Pk.g(function(f){return $CLJS.eg.g($CLJS.Cf,f)},$CLJS.Wk),0,
$CLJS.LW(a,b)+1):c};boa=function(a,b){b=$CLJS.e0(a,b);return $CLJS.n(b)?$CLJS.S1(a,b):null};m2=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Qk.h(function(b){return $CLJS.me(b)&&$CLJS.Nk.g(b,$CLJS.Dj)}),$CLJS.Xg(a))};n2=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.gm(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.GE);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.eG.h(a))};
$CLJS.o2=function(a,b,c){var d=$CLJS.gl.l;b=$CLJS.Va($CLJS.j2(a,b))?function(){var f=$CLJS.VV.h($CLJS.A($CLJS.tL.h(a)));return $CLJS.n(f)?(f=boa(a,f),$CLJS.n(f)?$CLJS.BH(function(k){return $CLJS.E.g($CLJS.Yi.h(k),c)},f):null):null}():null;try{var e=$CLJS.E1(a,c)}catch(f){e=null}return d.call($CLJS.gl,$CLJS.H([b,e]))};
p2=function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null),d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.AP);d=$CLJS.J.g(d,$CLJS.LN);$CLJS.I(a,2,null);a=$CLJS.n(d)?$CLJS.E.g(d,$CLJS.dL.h(b)):d;return $CLJS.n(a)?a:$CLJS.E.g($CLJS.FA($CLJS.g_,$CLJS.e_)(b),c)};
coa=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null),d=$CLJS.Fe($CLJS.lf(function(e){var f=$CLJS.E.g($CLJS.SZ.h(e),c);return f?p2(a,e):f},b));return $CLJS.n(d)?d:$CLJS.lf(function(e){var f=$CLJS.E.g($CLJS.T.h(e),c);return f?p2(a,e):f},b)};
doa=function(a,b,c){$CLJS.I(a,0,null);var d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.Fe($CLJS.lf(function(k){var l=$CLJS.E.g($CLJS.Yi.h(k),e);if(l){if(l=$CLJS.Va($CLJS.AP.h(d))){l=$CLJS.UK.h(k);var m=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.WZ,null,$CLJS.a_,null],null),null);l=m.h?m.h(l):m.call(null,l)}return $CLJS.n(l)?l:p2(a,k)}return l},b));if($CLJS.n(f))return f;b=$CLJS.n(c)?$CLJS.Fe($CLJS.lf(function(k){return $CLJS.E.g($CLJS.Yi.h(k),e)},b)):null;return $CLJS.n(b)?b:$CLJS.Cf};
eoa=function(a){var b=q2.g($CLJS.UK.h(a),$CLJS.MZ);return $CLJS.n(b)?b:$CLJS.yE.h(a)};foa=function(a,b){b=$CLJS.Fe($CLJS.Qk.g($CLJS.dL,b));if($CLJS.n(b))if($CLJS.Va($CLJS.B(b)))a=$CLJS.A(b);else{if($CLJS.n(q2.g($CLJS.A(a),$CLJS.pG))){var c=$CLJS.Fe($CLJS.Qk.g(eoa,b));c=$CLJS.n(c)?$CLJS.B(c)?null:$CLJS.A(c):null}else c=null;a=$CLJS.n(c)?c:console.warn($CLJS.hi("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.W,a,$CLJS.JW,b],null)))}else a=null;return a};
goa=function(a,b){b=$CLJS.Fe($CLJS.Qk.g(function(c){var d=$CLJS.FA($CLJS.g_,$CLJS.e_)(c);$CLJS.n(d)?(c=$CLJS.UK.h(c),d=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.WZ,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Va(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):foa(a,b):null};
ioa=function(a,b){var c=$CLJS.NE(a);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.AP);return $CLJS.n(d)?(b=$CLJS.Fe($CLJS.lf(function(e){return $CLJS.E.g($CLJS.FA($CLJS.g_,$CLJS.e_)(e),d)},b)),$CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):console.warn("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.W,a,hoa,b],null)):null):goa(a,b)};joa=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
koa=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.r2=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.loa=new $CLJS.M(null,"matching-refs","matching-refs",324693611);hoa=new $CLJS.M(null,"matches","matches",635497998);s2=new $CLJS.M(null,"left","left",-399115937);t2=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var q2=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.lC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.jC(f);k=$CLJS.jC(k);return $CLJS.Nk.g(f,k)?t2:f},e,a,b,c,d)}();q2.m(null,t2,function(){return!1});
q2.m(null,$CLJS.tB,function(a,b){var c=m2(a),d=m2(b);return $CLJS.E.g(c,d)&&$CLJS.Qe(function(e){return q2.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});q2.m(null,$CLJS.qB,function(a,b){var c=$CLJS.E.g($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.y(a),$CLJS.A(c),$CLJS.B(c),c=$CLJS.y(b),$CLJS.A(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.y(c);b=$CLJS.A(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.y(a);d=$CLJS.A(a);a=$CLJS.B(a);c=q2.g(c,d);if($CLJS.n(c)){if(c=$CLJS.td(b))return c;c=b}else return c}else return c});var f2=null;
q2.m(null,$CLJS.bW,function(a,b){var c=f2;f2=new $CLJS.h(null,2,[s2,n2(a),$CLJS.Ax,n2(b)],null);try{var d=$CLJS.tI(q2,$CLJS.tB);return d.g?d.g(a,b):d.call(null,a,b)}finally{f2=c}});
q2.m(null,$CLJS.eG,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.H([$CLJS.D(b)])))?(c=$CLJS.E.g(c,f))?(d=q2.g(d,k),$CLJS.n(d)?$CLJS.n(f2)?$CLJS.E.g(g2(new $CLJS.P(null,2,5,$CLJS.Q,[s2,e],null),koa),g2(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ax,l],null),joa)):$CLJS.E.g(e,l):d):c:a});
q2.m(null,$CLJS.ci,function(a,b){if($CLJS.xd(a)){var c=$CLJS.tI(q2,$CLJS.tB);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.wd(a)?(c=$CLJS.tI(q2,$CLJS.qB),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.E.g(a,b)});
$CLJS.u2=function(){function a(f,k,l,m,t){var u=$CLJS.EW(l)?l:$CLJS.xW(l);$CLJS.I(u,0,null);$CLJS.I(u,1,null);var v=$CLJS.I(u,2,null),x=e.j?e.j(u,m,t):e.call(null,u,m,t);return $CLJS.n(x)?x:$CLJS.n($CLJS.n(f)?"number"===typeof v:f)&&(m=$CLJS.Fe($CLJS.Qk.g($CLJS.Yi,m)),$CLJS.n(m)&&(k=$CLJS.EW(l)?$CLJS.o2(f,k,v):l,$CLJS.n(k)))?(f=$CLJS.R.j,l=$CLJS.SZ.h(k),k=$CLJS.n(l)?l:$CLJS.T.h(k),u=$CLJS.PE(f.call($CLJS.R,u,2,k),$CLJS.Ve($CLJS.gl,new $CLJS.h(null,1,[$CLJS.xB,$CLJS.zj],null))),e.j?e.j(u,m,t):e.call(null,
u,m,t)):null}function b(f,k,l,m){return e.N?e.N(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=$CLJS.I(f,0,null);$CLJS.I(f,1,null);var t=$CLJS.I(f,2,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.r2);switch(m instanceof $CLJS.M?m.T:null){case "aggregation":return $CLJS.BH(function(u){return $CLJS.E.g($CLJS.UK.h(u),$CLJS.mL)&&$CLJS.E.g($CLJS.l1.h(u),t)},k);case "expression":case "field":switch(k="string"===typeof t?coa(f,k):doa(f,k,l),$CLJS.D(k)){case 0:return null;case 1:return $CLJS.A(k);
default:return ioa(f,k)}default:throw $CLJS.hi("Unknown type of ref",new $CLJS.h(null,1,[$CLJS.W,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.N=a;return e}();
$CLJS.moa=function(){function a(d,e,f,k){return $CLJS.u2.v(d,e,$CLJS.BZ.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();