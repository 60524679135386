var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var GL,IL,KL,NL,SL;$CLJS.BL=function(a){return $CLJS.Wh($CLJS.q($CLJS.lC),a,$CLJS.tG)};$CLJS.CL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.DL=new $CLJS.M(null,"row","row",-570139521);$CLJS.EL=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.FL=new $CLJS.M(null,"object-id","object-id",-754527291);GL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.HL=new $CLJS.M(null,"operators","operators",-2064102509);IL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.JL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);KL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.LL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.ML=new $CLJS.M(null,"dimensions","dimensions",-254818097);
NL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.OL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.PL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.QL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.RL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
SL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.TL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.UL=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);$CLJS.X(IL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.EE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.kH],null)],null)],null));$CLJS.X(SL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,IL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xB,$CLJS.IE],null)],null)],null));$CLJS.X(KL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.pG],null),SL,$CLJS.zE],null));
$CLJS.X(NL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.pG],null),IL,$CLJS.kL],null));
$CLJS.QG.g($CLJS.pG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.pG],null),IL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.kL,$CLJS.zE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.Ni,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.jC(a)):null},$CLJS.xt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.oB,NL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nB,KL],null)],null)],null));$CLJS.xF($CLJS.pG,$CLJS.tG);$CLJS.TE.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.FA($CLJS.Ei,$CLJS.xB)(b);return $CLJS.n(a)?a:$CLJS.fF});$CLJS.EF($CLJS.uB,$CLJS.H([$CLJS.zE]));$CLJS.TE.m(null,$CLJS.uB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.FA($CLJS.Ei,$CLJS.xB)(b);return $CLJS.n(a)?a:$CLJS.fF});
$CLJS.xF($CLJS.uB,$CLJS.tG);$CLJS.X(GL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,$CLJS.EE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.zE],null)],null)],null));$CLJS.QG.g($CLJS.eG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.eG],null),GL,$CLJS.Yj],null));
$CLJS.TE.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.FA($CLJS.Ei,$CLJS.xB)(b);return $CLJS.n(a)?a:$CLJS.fF});$CLJS.xF($CLJS.eG,$CLJS.tG);$CLJS.EF($CLJS.OF,$CLJS.H([$CLJS.qt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VK],null)],null)]));$CLJS.xF($CLJS.OF,$CLJS.tG);
$CLJS.EF($CLJS.kG,$CLJS.H([$CLJS.qt,$CLJS.fF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TK],null)],null)]));$CLJS.xF($CLJS.kG,$CLJS.tG);
$CLJS.X($CLJS.tG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.VE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.It(", ",$CLJS.bH($CLJS.Jd,$CLJS.kl.g($CLJS.q($CLJS.lC),$CLJS.tG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.BL(a)}],null)],null));