var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var PV,RV,SV,Mka,WV,XV,YV,Oka,ZV,$V,aW,cW,dW,eW,fW,gW,hW,jW,kW,lW,mW,nW,qW,rW,Nka;PV=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Qk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.me(c)}),a)};
$CLJS.QV=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Se.g($CLJS.DC,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v);$CLJS.ue(t,$CLJS.PE(x,function(){return function(z){return $CLJS.Hk.l(PV(z),$CLJS.xB,$CLJS.H([$CLJS.Ei]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),e($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k);return $CLJS.ee($CLJS.PE(u,function(){return function(v){return $CLJS.Hk.l(PV(v),$CLJS.xB,$CLJS.H([$CLJS.Ei]))}}(u,k,f,b)),e($CLJS.Lc(k)))}return null}},null,null)}(a)}())};
RV=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cf,a],null)],null);for(var c=$CLJS.Cf;;){var d=$CLJS.rd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.sd(a);a=function(t,u,v,x,z,C,G,K){return function(S){return $CLJS.eg.j(v,$CLJS.hf.h(function(V,Z,ha,ra,Na){return function(zb){var Pa=$CLJS.I(zb,0,null);zb=$CLJS.I(zb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.be.g(Na,Pa),zb],null)}}(t,u,v,x,z,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.be.g(c,l)):a=$CLJS.xd(k)?a(k):$CLJS.wd(k)?a($CLJS.dm($CLJS.Rs,k)):m}else return c}};SV=function(a,b,c){return $CLJS.zd(c)&&$CLJS.E.g(a,$CLJS.A(c))&&!$CLJS.Hd(b,$CLJS.J.g(c,2))};$CLJS.TV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Pk.g($CLJS.yE,$CLJS.hd)),$CLJS.fQ.h(a));return RV($CLJS.Hk.l(a,$CLJS.KN,$CLJS.H([$CLJS.uV])),function(c){return SV($CLJS.uB,b,c)})};
$CLJS.UV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Pk.g($CLJS.GE,$CLJS.hd)),$CLJS.eG.h(a));return RV($CLJS.Hk.l(a,$CLJS.KN,$CLJS.H([$CLJS.uV])),function(c){return SV($CLJS.eG,b,c)})};Mka=function(a){function b(d){return $CLJS.jf(c,$CLJS.H([$CLJS.KN.h(d)]))}function c(d){return $CLJS.ee($CLJS.cF.h(d),$CLJS.jf(b,$CLJS.H([$CLJS.tL.h(d)])))}return $CLJS.n($CLJS.VV.h(a))?$CLJS.Ue(!0):$CLJS.fh(b(a))};
WV=function(a){if($CLJS.wd(a))for(var b=$CLJS.Ue(!1),c=$CLJS.y(a),d=$CLJS.A(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.A(v),z=$CLJS.B(v),C=x,G=z,K=$CLJS.FA(m,Mka(C));f=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(S,V,Z,ha,ra,Na){return function eb(Pa,Ya){try{if($CLJS.zd(Ya)&&3===$CLJS.D(Ya))try{var Sa=$CLJS.F(Ya,0);if($CLJS.he(Sa,$CLJS.pG))try{var mb=$CLJS.F(Ya,1);if($CLJS.n($CLJS.AP.h(mb)))try{var Ja=$CLJS.F(Ya,1);if(null!=Ja?Ja.C&256||$CLJS.Bc===Ja.mf||(Ja.C?0:$CLJS.$a($CLJS.wb,
Ja)):$CLJS.$a($CLJS.wb,Ja))try{var bc=$CLJS.J.j(Ja,$CLJS.AP,$CLJS.KV);if($CLJS.n($CLJS.Te(ha)(bc))){var $d=$CLJS.J.g(Ja,$CLJS.AP);$CLJS.F(Ya,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Na),": no join named ",$CLJS.Mh.l($CLJS.H([$d]))].join("")],null)}throw $CLJS.Y;}catch(qd){if(qd instanceof Error){var Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;
}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)return $CLJS.HV(eb,Pa,Ya);throw Id;}throw qd;}}}(f,k,l,K,m,t,u,v,x,z,C,G,b,0,a,a,c,d,e,d,e)($CLJS.Cf,$CLJS.Hk.l(C,$CLJS.KN,$CLJS.H([Nka]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};XV=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);YV=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Oka=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);ZV=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
$V=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);aW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.bW=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);cW=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
dW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);eW=new $CLJS.r(null,"refs","refs",80480079,null);fW=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);gW=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);hW=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.iW=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);jW=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);kW=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);lW=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);mW=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
nW=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.oW=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.VV=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.pW=new $CLJS.M(null,"filters","filters",974726919);qW=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);rW=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.sW=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Nka=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(fW,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.iW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qQ,$CLJS.Wa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Dl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NQ,new $CLJS.h(null,1,[$CLJS.Ss,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mV],null)],null)],null));$CLJS.X(kW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tG],null));
$CLJS.X(XV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),kW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Breakouts must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.QV},YV,$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.yk,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.yV,ZV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[eW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.QV)?$CLJS.QV.H:null]))],null)],null));
$CLJS.X(lW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,":fields must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.QV},YV,$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.yk,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.yV,ZV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,
1,5,$CLJS.Q,[eW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.QV)?$CLJS.QV.H:null]))],null)],null));$CLJS.X(mW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.OF],null),$CLJS.hk,$CLJS.Yj],null)],null));
$CLJS.X(jW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),mW],null));
var tW=$CLJS.FA(function(a){a=$CLJS.A($CLJS.TV(a));if($CLJS.n(a)){var b=$CLJS.GA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Mh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.A($CLJS.UV(a));if($CLJS.n(a)){var b=$CLJS.GA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(nW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,2,[$CLJS.xt,"Valid references for a single query stage",$CLJS.zt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Cj);return tW(a)}],null),$CLJS.Te(tW)],null));
$CLJS.X($CLJS.sW,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.bW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.dG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),XV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),lW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pW,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),jW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zL],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VV,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bL],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Hd(a,$CLJS.PQ)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,
new $CLJS.h(null,1,[$CLJS.xt,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Te($CLJS.nE($CLJS.lP,$CLJS.VV))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nW],null)],null));$CLJS.X($V,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null)],null)],null)],null));
$CLJS.X(aW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bL],null)],null)],null)],null));$CLJS.X(dW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$V],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aW],null)],null));
$CLJS.X(gW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Te($CLJS.FA($CLJS.lP,$CLJS.VV))],null)],null));$CLJS.X(hW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,$CLJS.iW,$CLJS.bW],null));
$CLJS.X(Oka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,hW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.Dj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sW],null)],null)],null)],null));
$CLJS.X(rW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,hW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.Dj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dW],null)],null)],null)],null));$CLJS.X(cW,gW);
$CLJS.X(qW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,2,[$CLJS.xt,"Valid references for all query stages",$CLJS.zt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Cj);return WV.h?WV.h(a):WV.call(null,a)}],null),$CLJS.Te(WV)],null));
$CLJS.X($CLJS.pL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cW],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qW],null)],null));
$CLJS.X($CLJS.RL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.oW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.cL,$CLJS.hL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pL],null)],null)],null),$CLJS.Fka],null));