var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var qu,su,tu,uu,vu,wu,xu,Eu,Fu,Gu,Hu,Iu,Aba,Lu,Bba,Ou,Pu,Ru,Su,Tu,Uu,Vu,Wu,av,dv,fv,iv,Cba,Dba,nv,Eba,pv,qv,rv,xv,yv,Gv,Kv,Qv,Sv,Xv,aw,fw,hw,iw,cw,kw,lw,nw,mw,ow,Iba,tw,Cw,Dw,ww,Kba,Hw,Kw,Mw,Nw,Mba,Nba,Oba,Rw,Vw,Ww,Xw,Yw,Pba,cx,fx,Qba,Rba,Sba,Tba,Uba,Vba,Wba,Xba,Yba,$ba,kx,Zba,ox,aca,px,bca,cca,qx,dca,rx,eca,fca,gca,ica,jca,lca,mca,oca,qca,wx,zx,sca,rca,tca,bw,ew,Gx,Hx,Ix,Jx,Mx,Nx,wca,xca,yca,Qx,zca,Rx,Px,Sx,Zu,Tx,mv,ex,Aca,Uv,Bca,Cca,bv,kv,Kx,Dca,Iv,sw,sv,Eca,Fca,Fv,Gca,xx,wv,rw,Dv,
gx,Hca,lv,Ica,nx,ux,Ux,Jca,Ow,Wx,Qw,Hv,qw,Yu,tx,Hba,Tv,Bv,ev,Kca,jw,Lca,zw,Pw,Bx,Mca,Dx,Gba,Nca,Oca,Pca,Yv,Qca,lx,Wv,Rca,Aw,yw,Sca,gv,Tca,vca,Ex,tv,Yx,Tw,Zx,$x,Ew,Uca,vv,Vca,uca,Wca,$v,jx,pw,Xca,Uw,Lx,Yca,ix,Zca,xw,jv,$ca,Jv,by,Sw,ada,cv,sx,Vv,Fba,vx,hv,Zw,yx,dx,$w,bx,mx,Fw,cy,dy,bda,Qu,cda,Nu,Xu,dda,uv,eda;qu=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.ru=function(){};
su=function(a){if(null!=a&&null!=a.rc)a=a.rc(a);else{var b=su[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=su._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IWriter.-flush",a);}return a};tu=function(){};uu=function(a){if(null!=a&&null!=a.Lc)a=a.Lc(a);else{var b=uu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=uu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPending.-realized?",a);}return a};
vu=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};wu=function(a,b){return a-b*$CLJS.Vd(a,b)};xu=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=xu[$CLJS.wa(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=xu._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.bb("IMultiFn.-add-method",a);}};
$CLJS.yu=function(a,b){var c=$CLJS.ec($CLJS.N);a=$CLJS.y(a);for(b=$CLJS.y(b);;)if(a&&b)c=$CLJS.hg.j(c,$CLJS.A(a),$CLJS.A(b)),a=$CLJS.B(a),b=$CLJS.B(b);else return $CLJS.gc(c)};$CLJS.zu=function(a,b){return $CLJS.lh(a,b)};$CLJS.Au=function(a,b){return new $CLJS.ne(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.ee($CLJS.A(c),$CLJS.Au(a,$CLJS.Lc(c))):null}else c=null;return c},null,null)};
$CLJS.Bu=function(a,b){return $CLJS.hf.j(function(c){return c},b,$CLJS.bf(a,b))};Eu=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.Se.g(Cu,0<b.length?new $CLJS.w(b.slice(0),0,null):null);$CLJS.ac($CLJS.Du,"\n")};Fu=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Gu=function(a,b,c){var d=c;for(c=$CLJS.Cf;;){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null);var e=$CLJS.A(d);d=$CLJS.B(d);e=$CLJS.Se.g(a,new $CLJS.P(null,2,5,$CLJS.Q,[e,b],null));b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);c=$CLJS.be.g(c,b);b=e}};Hu=function(a,b){var c=b;for(b=$CLJS.Cf;;){var d=$CLJS.Se.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[c],null));c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.Va(c))return new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null);b=$CLJS.be.g(b,c);c=d}};
Iu=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);var v=$CLJS.I(t,0,null);$CLJS.I(t,1,null);l.add(new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);k=$CLJS.I(f,0,null);$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()),$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);l.add(new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())],null)};
Aba=function(a,b){return $CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,new $CLJS.P(null,2,5,$CLJS.Q,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);
k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,new $CLJS.P(null,2,5,$CLJS.Q,[k,b],null)],null),e($CLJS.Lc(f)))}return null}},null,null)}(a)}())};$CLJS.Ju=function(){var a=$CLJS.Du;if(null!=a&&null!=a.uf)a.uf(a);else{var b=$CLJS.Ju[$CLJS.wa(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.Ju._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPrettyFlush.-ppflush",a);}};$CLJS.Ku=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
Lu=function(a,b,c){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,b,c)};Bba=function(a,b){$CLJS.E.g(b,"\n")?(Lu(a,$CLJS.Mu,0),Lu(a,$CLJS.mj,$CLJS.Ku(a,$CLJS.mj)+1)):Lu(a,$CLJS.Mu,$CLJS.Ku(a,$CLJS.Mu)+1);return $CLJS.ac($CLJS.Ku(a,Nu),b)};Ou=function(a,b,c,d){this.Ca=a;this.Nf=b;this.Yc=c;this.bi=d;this.C=1074167808;this.I=0};
Pu=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.Cb=b;this.tb=c;this.sb=d;this.vb=e;this.wb=f;this.prefix=k;this.Ab=l;this.Db=m;this.xb=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};Ru=function(a,b){for(b=Qu.h(b);;){if(null==b)return!1;if(a===b)return!0;b=Qu.h(b)}};Su=function(a,b,c,d,e,f,k,l){this.ca=a;this.data=b;this.ac=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
Tu=function(a,b,c,d,e,f,k,l){this.ca=a;this.type=b;this.ea=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};Uu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};Vu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Wu=function(a,b,c,d,e,f,k,l,m){this.ca=a;this.ea=b;this.Wb=c;this.offset=d;this.ba=e;this.aa=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
av=function(a,b,c){b=$CLJS.y(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.E.g(Xu.h(k),Yu)){var l=Zu.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),l)}$u.g(a,k);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Zu,Zu.h(k));k=Zu.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Zu,null));f+=1}else if(b=$CLJS.y(b))$CLJS.Ad(b)?(d=$CLJS.lc(b),b=$CLJS.mc(b),k=d,e=$CLJS.D(d),d=k):(k=$CLJS.A(b),$CLJS.E.g(Xu.h(k),Yu)||
(d=Zu.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),d)),$u.g(a,k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Zu,Zu.h(k)),k=Zu.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Zu,null)),b=$CLJS.B(b),d=null,e=0),f=0;else break};dv=function(a,b){var c=$CLJS.Ku(Nu.h($CLJS.q($CLJS.q(a))),$CLJS.ik),d;(d=null==c)||(a=$CLJS.Ku(Nu.h($CLJS.q($CLJS.q(a))),$CLJS.Mu),b=(b=$CLJS.y(b))?bv.h($CLJS.id(b))-cv.h($CLJS.A(b)):0,d=a+b<c);return d};
fv=function(a,b,c){b=$CLJS.q(ev.h(b));return $CLJS.n(b)?b:!dv(a,c)};iv=function(a,b,c){var d=gv.h($CLJS.q($CLJS.q(a))),e=$CLJS.Ku(Nu.h($CLJS.q($CLJS.q(a))),$CLJS.ik);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(hv.h(b))>=e-d)?fv(a,b,c):d:e:d};Cba=function(a){var b=$CLJS.A(a),c=jv.h(b);b=$CLJS.y($CLJS.Au(function(d){return!($CLJS.E.g(Xu.h(d),Yu)&&Ru(jv.h(d),c))},$CLJS.B(a)));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b)+1,a))],null)};
Dba=function(a){var b=$CLJS.A(a),c=jv.h(b);return $CLJS.y($CLJS.Au(function(d){var e=jv.h(d);return!($CLJS.E.g(Xu.h(d),Yu)&&($CLJS.E.g(e,c)||Ru(e,c)))},$CLJS.B(a)))};
nv=function(a,b){$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Zu,null);b=jv.h(b);var c=kv.h(b);$CLJS.n(c)&&$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.Se.g($CLJS.p,$CLJS.df($CLJS.q(lv.h(b))-$CLJS.D(c)," "));$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.Ye(mv.h(b),!0),$CLJS.Ye(ev.h(b),!0),a=Qu.h(b);;)if($CLJS.n(a))$CLJS.Ye(ev.h(a),!0),$CLJS.Ye(mv.h(a),!0),a=Qu.h(a);else break a;return null};
Eba=function(a){var b=$CLJS.y($CLJS.Au(function(c){return!$CLJS.E.g(Xu.h(c),Yu)},a));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b),a))],null)};pv=function(a){for(var b=$CLJS.Lt.h($CLJS.q($CLJS.q(a)));;){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Lt,$CLJS.eg.g($CLJS.Cf,b));if(dv(a,b))return null;var c=ov(a,b);if(b!==c)b=c;else return null}};
qv=function(a,b){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Lt,$CLJS.be.g($CLJS.Lt.h($CLJS.q($CLJS.q(a))),b));return dv(a,$CLJS.Lt.h($CLJS.q($CLJS.q(a))))?null:pv(a)};rv=function(a){var b=Zu.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),b),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Zu,null)):null};
xv=function(a,b){var c=$CLJS.Jt(b,"\n",-1);if($CLJS.E.g($CLJS.D(c),1))return b;b=kv.h($CLJS.A(sv.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.A(c);if($CLJS.E.g(tv,$CLJS.xi.h($CLJS.q($CLJS.q(a))))){var e=uv.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.D(d);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,uv,f);qv(a,new Su(vv,d,null,e,f,null,null,null));pv(a);d=$CLJS.Lt.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(av(a,d,!0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Lt,$CLJS.Cf))}else rv(a),$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),d);$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.y($CLJS.B($CLJS.Ft(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),l);$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.y(d))e=d,$CLJS.Ad(e)?(d=$CLJS.lc(e),k=$CLJS.mc(e),e=d,f=$CLJS.D(d),d=k):(d=$CLJS.A(e),$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),d),$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.B(e),e=null,f=0),k=0;else break;$CLJS.Oh.v($CLJS.q(a),
$CLJS.R,tv,wv);return $CLJS.id(c)};yv=function(a,b,c,d,e,f){this.Ca=a;this.Nf=b;this.li=c;this.mh=d;this.Yc=e;this.ci=f;this.C=1074167808;this.I=0};
$CLJS.Cv=function(a){var b=$CLJS.zv,c=Av,d=new Pu(null,null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),null,null,null,null,null,null,null),e=$CLJS.Xe($CLJS.Ig([sv,gv,Fba,Bv,Gba,$CLJS.xi,uv,Zu,Nu,Hba,$CLJS.Lt],[d,c,d,!0,null,wv,0,null,new Ou(a,b,$CLJS.Xe(new $CLJS.h(null,4,[$CLJS.ik,b,$CLJS.Mu,0,$CLJS.mj,0,Nu,a],null)),$CLJS.N),1,$CLJS.Cf]));return new yv(a,b,c,d,e,$CLJS.N)};
Gv=function(a,b){var c=$CLJS.Du;b=new Pu(sv.h($CLJS.q($CLJS.q(c))),null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),a,null,b,null,null,null,null);$CLJS.Oh.v($CLJS.q(c),$CLJS.R,sv,b);if($CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(c))),wv)){rv(c);var d=Dv.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.Ev):d.call(null,$CLJS.Ev));$CLJS.n(a)&&$CLJS.ac(Nu.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.Ku(Nu.h($CLJS.q($CLJS.q(c))),$CLJS.Mu);$CLJS.Ye(b.tb,c);$CLJS.Ye(b.sb,c)}else d=uv.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.D(a):0),$CLJS.Oh.v($CLJS.q(c),$CLJS.R,uv,a),qv(c,new Uu(Fv,b,d,a,null,null,null))};
Kv=function(){var a=$CLJS.Du,b=sv.h($CLJS.q($CLJS.q(a))),c=Hv.h(b);if($CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(a))),wv)){rv(a);$CLJS.n(c)&&$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),c);var d=Dv.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(Iv):d.call(null,Iv))}else d=uv.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.D(c):0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,uv,c),qv(a,new Vu(Jv,b,d,c,null,null,null));$CLJS.Oh.v($CLJS.q(a),$CLJS.R,sv,Qu.h(b))};
$CLJS.Lv=function(a){var b=null!=a?a.C&32768||$CLJS.Bc===a.ag?!0:a.C?!1:$CLJS.$a($CLJS.ru,a):$CLJS.$a($CLJS.ru,a);return b?Bv.h($CLJS.q($CLJS.q(a))):b};$CLJS.Pv=function(a){var b=Mv;$CLJS.n(b)&&(b=$CLJS.Ma,b=$CLJS.n(b)?Mv>=$CLJS.Ma:b);$CLJS.Va($CLJS.Nv)?Cu.call(null,a):$CLJS.n(b)?$CLJS.ac($CLJS.Du,"..."):($CLJS.n(Mv)&&(Mv+=1),Ov.call(null,a));return b};
Qv=function(a,b){if($CLJS.Va(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};Sv=function(){var a=$CLJS.qh;return $CLJS.n(a)?Rv>=$CLJS.qh:a};Xv=function(a){Qv(a,new $CLJS.ah(null,new $CLJS.h(null,4,[Tv,null,Uv,null,Vv,null,Wv,null],null),null));var b=$CLJS.Du;$CLJS.Oh.v($CLJS.q(b),$CLJS.R,$CLJS.xi,tv);var c=uv.h($CLJS.q($CLJS.q(b))),d=sv.h($CLJS.q($CLJS.q(b)));qv(b,new Tu(Yu,a,d,c,c,null,null,null))};
aw=function(a,b){Qv(a,new $CLJS.ah(null,new $CLJS.h(null,2,[Yv,null,$CLJS.Zv,null],null),null));var c=$CLJS.Du,d=sv.h($CLJS.q($CLJS.q(c)));if($CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(c))),wv)){rv(c);var e=lv.h(d);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Yv,a):$CLJS.E.call(null,Yv,a)))a=$CLJS.q(hv.h(d));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.Zv,a):$CLJS.E.call(null,$CLJS.Zv,a)))a=$CLJS.Ku(Nu.h($CLJS.q($CLJS.q(c))),$CLJS.Mu);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.Ye(e,b+a)}else e=
uv.h($CLJS.q($CLJS.q(c))),qv(c,new Wu($v,d,a,b,e,e,null,null,null))};fw=function(a,b,c){b="string"===typeof b?bw(b):b;c=cw(c);a:{var d=new $CLJS.Ea,e=$CLJS.Va(a)||!0===a?new $CLJS.sc(d):a,f=dw(b)&&$CLJS.Va($CLJS.Lv(e))?$CLJS.n($CLJS.Lv(e))?e:$CLJS.Cv(e):e,k=$CLJS.Du;$CLJS.Du=f;try{try{ew(b,c)}finally{e!==f&&su(f)}var l=$CLJS.Va(a)?$CLJS.p.h(d):!0===a?$CLJS.uh($CLJS.p.h(d)):null;break a}finally{$CLJS.Du=k}l=void 0}return l};
hw=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(gw),"\n",$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(b," "))),"^\n"].join("");throw Error(a);};iw=function(a,b,c,d,e,f){this.uc=a;this.pb=b;this.$b=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};cw=function(a){a=$CLJS.y(a);return new iw(a,a,0,null,null,null)};
kw=function(a){var b=$CLJS.Oi.h(a);if($CLJS.n(b))return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),new iw(jw.h(a),$CLJS.B(b),uv.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};lw=function(a){var b=kw(a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);a="string"===typeof a?bw(a):a;return new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null)};nw=function(a,b){return b>=uv.h(a)?(b=uv.h(a)-b,mw.g?mw.g(a,b):mw.call(null,a,b)):new iw(jw.h(a),$CLJS.bf(b,jw.h(a)),b,null,null,null)};
mw=function(a,b){var c=uv.h(a)+b;return 0>b?nw(a,c):new iw(jw.h(a),$CLJS.bf(b,$CLJS.Oi.h(a)),c,null,null,null)};ow=function(a,b,c,d,e,f,k){this.dc=a;this.cc=b;this.kc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Iba=function(a,b){var c=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);d=$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,2,[pw,null,qw,null],null),null),c)?new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null):$CLJS.E.g(d,rw)?kw(b):$CLJS.E.g(d,sw)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.D($CLJS.Oi.h(b)),b],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.P(null,2,5,$CLJS.Q,
[b,a],null)],null),d],null)};tw=function(a,b){b=Gu(Iba,b,a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,a),b],null)};
Cw=function(a,b,c){c=kw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d))if($CLJS.E.g(uw,10))var e=[$CLJS.p.h(d),$CLJS.n(vw)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(vw)){var k=$CLJS.J.g(Jba,uw);k=$CLJS.n(k)?k:["#",$CLJS.p.h(uw),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(ww(uw,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+xw.h(b);e=e>=yw.h(b)?e:e+($CLJS.Vd(yw.h(b)-e-1,zw.h(b))+1)*zw.h(b);d=$CLJS.Se.g($CLJS.p,$CLJS.df(e-d,Aw.h(b)));
$CLJS.n(qw.h(b))?Bw.l($CLJS.H([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):Bw.l($CLJS.H([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};Dw=function(a,b){return $CLJS.ce($CLJS.A(Hu(function(c){return 0<c?new $CLJS.P(null,2,5,$CLJS.Q,[wu(c,a),$CLJS.Vd(c,a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null)},b)))};ww=function(a,b){return 0===b?"0":$CLJS.Se.g($CLJS.p,$CLJS.hf.g(function(c){return 10>c?vu(Fu("0")+c):vu(Fu("a")+(c-10))},Dw(a,b)))};
Kba=function(a,b){return $CLJS.ce($CLJS.A(Hu(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y($CLJS.ce($CLJS.af(a,c))),$CLJS.y($CLJS.bf(a,c))],null)},$CLJS.ce(b))))};
Hw=function(a,b,c){c=kw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.E.g(d,Math.floor(d))){var e=0>d,f=ww(a,e?-d:d);a=$CLJS.n(pw.h(b))?function(){var k=$CLJS.hf.g(function(m){return $CLJS.Se.g($CLJS.p,m)},Kba(Ew.h(b),f)),l=$CLJS.df($CLJS.D(k),Fw.h(b));return $CLJS.Se.g($CLJS.p,$CLJS.B($CLJS.ef.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(qw.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<
yw.h(b)?[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(yw.h(b)-a.length,Aw.h(b)))),$CLJS.p.h(a)].join(""):a;Bw.l($CLJS.H([a]))}else Cw($CLJS.Gw,new $CLJS.h(null,5,[yw,yw.h(b),zw,1,xw,0,Aw,Aw.h(b),qw,!0],null),cw(new $CLJS.P(null,1,5,$CLJS.Q,[d],null)));return c};
Kw=function(a){var b=$CLJS.Vd(a,100);a=wu(a,100);var c=0<b?[$CLJS.p.h($CLJS.F(Iw,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.F(Iw,a);else{f=$CLJS.Vd(a,10);var k=wu(a,10);f=[$CLJS.p.h(0<f?$CLJS.F(Jw,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.F(Iw,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
Mw=function(a,b){var c=$CLJS.D(a),d=$CLJS.Cf;--c;var e=$CLJS.A(a);for(a=$CLJS.B(a);;){if(null==a)return[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.ff(", ",d))),$CLJS.td(e)||$CLJS.td(d)?null:", ",$CLJS.p.h(e),!$CLJS.td(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.F(Lw,c+b))].join(""):null].join("");d=$CLJS.td(e)?d:$CLJS.be.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.F(Lw,c+b))].join(""));--c;e=$CLJS.A(a);a=$CLJS.B(a)}};
Nw=function(a,b){b=kw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=Dw(10,c);c=$CLJS.Cf;for(var e=$CLJS.D(d)-1;;)if($CLJS.td(d)){Bw.l($CLJS.H([$CLJS.Se.g($CLJS.p,c)]));break}else{var f=$CLJS.A(d);c=$CLJS.E.g(0,f)?c:$CLJS.be.g(c,$CLJS.F($CLJS.F(a,e),f-1));--e;d=$CLJS.B(d)}}else Hw(10,new $CLJS.h(null,5,[yw,0,Aw," ",Fw,",",Ew,3,pw,!0],null),cw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null)));return b};
Mba=function(a,b){a=kw(b);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var c=Fu(b);b=c&127;c&=128;var d=$CLJS.J.g(Lba,b);0<c&&Bw.l($CLJS.H(["Meta-"]));Bw.l($CLJS.H([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h(vu(b+64))].join(""):$CLJS.E.g(b,127)?"Control-?":vu(b)]));return a};
Nba=function(a,b){var c=kw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);a=Ow.h(a);if($CLJS.n($CLJS.E.g?$CLJS.E.g("o",a):$CLJS.E.call(null,"o",a)))fw(!0,"\\o~3,'0o",$CLJS.H([Fu(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g("u",a):$CLJS.E.call(null,"u",a)))fw(!0,"\\u~4,'0x",$CLJS.H([Fu(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g(null,a):$CLJS.E.call(null,null,a)))$CLJS.ac($CLJS.Du,$CLJS.n($CLJS.E.g?$CLJS.E.g("\b",b):$CLJS.E.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.E.g?$CLJS.E.g(" ",b):$CLJS.E.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.E.g?$CLJS.E.g("\t",b):$CLJS.E.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.E.g?$CLJS.E.g("\n",b):$CLJS.E.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.E.g?$CLJS.E.g("\f",b):$CLJS.E.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.E.g?$CLJS.E.g("\r",b):$CLJS.E.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.E.g?$CLJS.E.g('"',b):$CLJS.E.call(null,'"',b))?'\\"':$CLJS.n($CLJS.E.g?$CLJS.E.g("\\",b):$CLJS.E.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};Oba=function(a,b){b=kw(b);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);Bw.l($CLJS.H([a]));return b};Rw=function(a){a=$CLJS.A(a);return $CLJS.E.g(Pw,a)||$CLJS.E.g(Qw,a)};
Vw=function(a,b,c){return $CLJS.hd(Gu(function(d,e){if(Rw(e))return new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=tw(Sw.h(d),e);var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=Iu(f);f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);f=$CLJS.R.j(f,Tw,c);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Uw.h(d),new $CLJS.P(null,3,5,$CLJS.Q,[f,e,k],null))],null)},b,a))};
Ww=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);a:if(c=$CLJS.D(b),0<c&&$CLJS.E.g($CLJS.F(b,
$CLJS.D(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.E.g($CLJS.F(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.D(c);if(0<d&&$CLJS.E.g($CLJS.F(c,0),"0"))for(var e=0;;){if($CLJS.E.g(e,d)||!$CLJS.E.g($CLJS.F(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.D(b)-$CLJS.D(c);a=0<$CLJS.D(a)&&$CLJS.E.g($CLJS.F(a,0),"+")?a.substring(1):a;return $CLJS.td(c)?new $CLJS.P(null,2,5,$CLJS.Q,["0",0],null):new $CLJS.P(null,2,5,$CLJS.Q,[c,parseInt(a,10)-b],null)};
Xw=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.D(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.E.g(c,0)?new $CLJS.P(null,4,5,$CLJS.Q,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.P(null,4,5,$CLJS.Q,[a,b,c,e],null);c=$CLJS.I(f,0,null);e=$CLJS.I(f,1,null);d=$CLJS.I(f,2,null);f=$CLJS.I(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.P(null,3,5,$CLJS.Q,["0",0,!1],null);if(f>d){b=$CLJS.F(c,d);a=c.substring(0,d);if(Fu(b)>=Fu("5")){a:for(b=
$CLJS.D(a)-1,c=b|0;;){if(0>c){b=$CLJS.Se.j($CLJS.p,"1",$CLJS.df(b+1,"0"));break a}if($CLJS.E.g("9",a.charAt(c)))--c;else{b=$CLJS.Se.v($CLJS.p,a.substring(0,c),vu(Fu(a.charAt(c))+1),$CLJS.df(b-c,"0"));break a}}a=$CLJS.D(b)>a.length;c=$CLJS.Q;a&&(d=$CLJS.D(b)-1,b=b.substring(0,d));return new $CLJS.P(null,3,5,c,[b,e,a],null)}return new $CLJS.P(null,3,5,$CLJS.Q,[a,e,!1],null)}}}return new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null)};
Yw=function(a,b,c){var d=0>b?new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);a=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);d=$CLJS.D(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Pba=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
cx=function(a,b){var c=Zw.h(a),d=$w.h(a);b=kw(b);var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var f=0>e?new $CLJS.P(null,2,5,$CLJS.Q,["-",-e],null):new $CLJS.P(null,2,5,$CLJS.Q,["+",e],null),k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);f=Ww(f);var l=$CLJS.I(f,0,null),m=$CLJS.I(f,1,null)+$CLJS.ax.h(a);f=function(){var x=qw.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Va(d)&&$CLJS.D(l)-1<=m,u=Xw(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.I(u,0,null);m=$CLJS.I(u,1,null);u=$CLJS.I(u,2,null);l=Yw(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.E.g(l.charAt(0),"0")&&$CLJS.E.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.E.g($CLJS.A(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?bx.h(a):x}())?Bw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(c,bx.h(a)))])):Bw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-v,Aw.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else Bw.l($CLJS.H([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
fx=function(a,b){b=kw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Ww(0>c?-c:c);$CLJS.I(d,0,null);for($CLJS.I(d,1,null);;){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=Zw.h(a),l=$w.h(a),m=dx.h(a),t=$CLJS.ax.h(a),u=function(){var K=ex.h(a);return $CLJS.n(K)?K:"E"}();d=function(){var K=qw.h(a);return $CLJS.n(K)?K:0>c}();var v=0>=t,x=f-(t-1),z=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.Se.g($CLJS.p,$CLJS.df(m-z.length,"0")):null),z].join("");var C=u.length;
x=$CLJS.D(e);e=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.Se.g($CLJS.p,$CLJS.df(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-C:null;e=Xw(e,0,$CLJS.E.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.I(e,0,null);$CLJS.I(e,1,null);z=$CLJS.I(e,2,null);e=Pba(x,t);l=$CLJS.E.g(t,$CLJS.D(x))&&null==l;if($CLJS.Va(z)){if($CLJS.n(k)){f=e.length+C;f=$CLJS.n(d)?f+1:f;var G=(v=v&&!$CLJS.E.g(f,k))?f+1:f;f=l&&G<k;$CLJS.n(function(){var K=
G>k;K||(K=m,K=$CLJS.n(K)?C-2>m:K);return $CLJS.n(K)?bx.h(a):K}())?Bw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(k,bx.h(a)))])):Bw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(k-G-(f?1:0),Aw.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else Bw.l($CLJS.H([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.P(null,2,5,$CLJS.Q,[x,f+1],null)}return b};
Qba=function(a,b,c){var d=kw(b),e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=Ww(0>e?-e:e);var f=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var k=Zw.h(a),l=$w.h(a),m=dx.h(a);d=$CLJS.E.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.D(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=cx(new $CLJS.h(null,6,[Zw,k,$w,d,$CLJS.ax,0,bx,bx.h(a),Aw,Aw.h(a),qw,qw.h(a)],null),b,c),Bw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(e," "))])),a):fx(a,b,c)};
Rba=function(a,b){b=kw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Ww(Math.abs(c)),e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=$w.h(a),l=$CLJS.rk.h(a);d=Zw.h(a);var m=function(){var u=qw.h(a);return $CLJS.n(u)?u:0>c}(),t=Xw(e,f,k,null);e=$CLJS.I(t,0,null);f=$CLJS.I(t,1,null);t=$CLJS.I(t,2,null);k=Yw(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);Bw.l($CLJS.H([[$CLJS.n(function(){var u=pw.h(a);return $CLJS.n(u)?
m:u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(d-k,Aw.h(a)))),$CLJS.n(function(){var u=$CLJS.Va(pw.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};Sba=function(a,b){var c=gx.h(a);c=$CLJS.n(c)?new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null):kw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.hx.h(a);b=0>b||b>=$CLJS.D(d)?$CLJS.A($CLJS.Mt.h(a)):$CLJS.F(d,b);return $CLJS.n(b)?Vw(b,c,Tw.h(a)):c};
Tba=function(a,b){var c=kw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.hx.h(a);b=$CLJS.n(b)?$CLJS.hd(d):$CLJS.A(d);return $CLJS.n(b)?Vw(b,c,Tw.h(a)):c};Uba=function(a,b){var c=kw(b),d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.hx.h(a);e=$CLJS.n(d)?$CLJS.A(e):null;return $CLJS.n(d)?$CLJS.n(e)?Vw(e,b,Tw.h(a)):b:c};
Vba=function(a,b){var c=ix.h(a),d=$CLJS.A($CLJS.hx.h(a));d=$CLJS.td(d)?lw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=kw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=0;e=cw(e);for(var k=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(uv.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.td($CLJS.Oi.h(e))&&($CLJS.Va(pw.h(jx.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Vw(b,e,Tw.h(a));if($CLJS.E.g(Pw,$CLJS.A(k)))return d;
f+=1;var l=uv.h(e);e=k;k=l}};Wba=function(a,b){var c=ix.h(a),d=$CLJS.A($CLJS.hx.h(a));d=$CLJS.td(d)?lw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=kw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);for(var f=0;;){var k=$CLJS.td(e)&&($CLJS.Va(pw.h(jx.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Vw(b,cw($CLJS.A(e)),cw($CLJS.B(e)));if($CLJS.E.g(Qw,$CLJS.A(k)))return d;f+=1;e=$CLJS.B(e)}};
Xba=function(a,b){var c=ix.h(a),d=$CLJS.A($CLJS.hx.h(a)),e=$CLJS.td(d)?lw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;e=$CLJS.I(e,1,null);for(var f=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(uv.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.td($CLJS.Oi.h(e))&&($CLJS.Va(pw.h(jx.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=Vw(b,e,Tw.h(a));if($CLJS.E.g(Pw,$CLJS.A(f)))return $CLJS.hd(f);d+=1;var k=uv.h(e);e=f;f=k}};
Yba=function(a,b){var c=ix.h(a),d=$CLJS.A($CLJS.hx.h(a)),e=$CLJS.td(d)?lw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;for(e=$CLJS.I(e,1,null);;){var f=$CLJS.td($CLJS.Oi.h(e))&&($CLJS.Va(pw.h(jx.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.Oi.h(e);f=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(f),new iw(jw.h(e),$CLJS.B(f),uv.h(e)+1,null,null,null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=Vw(b,cw(e),f);
if($CLJS.E.g(Qw,$CLJS.A(e)))return f;e=f;d+=1}};$ba=function(a,b){if($CLJS.n(pw.h(jx.h(a)))){var c=$CLJS.hx.h(a),d=$CLJS.D(c),e=1<d?$CLJS.Yj.h(Sw.h($CLJS.A($CLJS.A(c)))):$CLJS.n(pw.h(a))?"(":null,f=$CLJS.F(c,1<d?1:0);c=2<d?$CLJS.Yj.h(Sw.h($CLJS.A($CLJS.F(c,2)))):$CLJS.n(pw.h(a))?")":null;d=kw(b);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var k=Rv,l=Mv;Rv+=1;Mv=0;try{Gv(e,c),Vw(f,cw(b),Tw.h(a)),Kv()}finally{Mv=l,Rv=k}}a=d}else a=Zba(a,b);return a};
kx=function(a,b,c){for(var d=$CLJS.Cf;;){if($CLJS.td(a))return new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);var e=$CLJS.A(a);a:{var f=new $CLJS.Ea,k=$CLJS.Du;$CLJS.Du=new $CLJS.sc(f);try{var l=new $CLJS.P(null,2,5,$CLJS.Q,[Vw(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.Du=k}l=void 0}b=$CLJS.I(l,0,null);e=$CLJS.I(l,1,null);if($CLJS.E.g(Pw,$CLJS.A(b)))return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.hd(b)],null);a=$CLJS.B(a);d=$CLJS.be.g(d,e)}};
Zba=function(a,b){var c=function(){var V=$CLJS.Mt.h(a);return $CLJS.n(V)?kx(V,b,Tw.h(a)):null}(),d=$CLJS.I(c,0,null);d=$CLJS.I(d,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var V=lx.h(a);return $CLJS.n(V)?tw(V,e):null}();var f=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var V=$CLJS.A(mx.h(f));return $CLJS.n(V)?V:0}();var l=function(){var V=$CLJS.A(nx.h(f));return $CLJS.n(V)?V:$CLJS.Ku($CLJS.Du,$CLJS.ik)}(),m=$CLJS.hx.h(a);k=kx(m,k,Tw.h(a));var t=$CLJS.I(k,
0,null);k=$CLJS.I(k,1,null);var u=function(){var V=$CLJS.D(t)-1+($CLJS.n(pw.h(a))?1:0)+($CLJS.n(qw.h(a))?1:0);return 1>V?1:V}();m=$CLJS.Qd($CLJS.Jk,$CLJS.hf.g($CLJS.D,t));var v=yw.h(a),x=xw.h(a),z=zw.h(a),C=m+u*x;v=C<=v?v:v+z*(1+$CLJS.Vd(C-v-1,z));var G=v-m;m=function(){var V=$CLJS.Vd(G,u);return x>V?x:V}();z=G-m*u;m=$CLJS.Se.g($CLJS.p,$CLJS.df(m,Aw.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.Ku(Nu.h($CLJS.q($CLJS.q($CLJS.Du))),$CLJS.Mu)+c+v>l:d)&&Bw.l($CLJS.H([d]));c=z;for(var K=t,S=function(){var V=pw.h(a);
return $CLJS.n(V)?V:$CLJS.E.g($CLJS.D(K),1)&&$CLJS.Va(qw.h(a))}();;)if($CLJS.y(K))Bw.l($CLJS.H([[$CLJS.p.h($CLJS.Va(S)?$CLJS.A(K):null),$CLJS.p.h($CLJS.n(function(){var V=S;return $CLJS.n(V)?V:(V=$CLJS.B(K))?V:qw.h(a)}())?m:null),$CLJS.p.h(0<c?Aw.h(a):null)].join("")])),--c,K=d=$CLJS.n(S)?K:$CLJS.B(K),S=!1;else break;return k};ox=function(a,b){this.Ca=a;this.di=b;this.C=1074135040;this.I=0};aca=function(a){return new ox(a,$CLJS.N)};px=function(a,b){this.Ca=a;this.ei=b;this.C=1074135040;this.I=0};
bca=function(a){return new px(a,$CLJS.N)};
cca=function(a,b){var c=$CLJS.A(a);return $CLJS.Se.g($CLJS.p,$CLJS.A(Hu(function(d){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[[d.substring(0,e),$CLJS.F(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?qu(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
qx=function(a,b,c){this.Ca=a;this.Jd=b;this.fi=c;this.C=1074135040;this.I=0};dca=function(a){return new qx(a,$CLJS.Xe(!0),$CLJS.N)};rx=function(a,b,c){this.Ca=a;this.vd=b;this.gi=c;this.C=1074135040;this.I=0};eca=function(a){return new rx(a,$CLJS.Xe(!1),$CLJS.N)};fca=function(a,b){var c=$CLJS.n(pw.h(a))?$CLJS.Zv:Yv;aw(c,$CLJS.rk.h(a));return b};gca=function(a,b){a=$CLJS.n(pw.h(a))?$CLJS.n(qw.h(a))?Tv:Vv:$CLJS.n(qw.h(a))?Uv:Wv;Xv(a);return b};
ica=function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null);a=new RegExp(hca.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.A(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.E.g(",",$CLJS.F(b,0))?new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b.substring(1),a+1,!0],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b,a,!1],null)],null)):$CLJS.n(d)?hw("Badly formed parameters in format directive",
c):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null)],null)};jca=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E.g(b.length,0)?null:$CLJS.E.g(b.length,1)&&$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.F(b,0))?rw:$CLJS.E.g(b.length,1)&&$CLJS.E.g("#",$CLJS.F(b,0))?sw:$CLJS.E.g(b.length,2)&&$CLJS.E.g("'",$CLJS.F(b,0))?$CLJS.F(b,1):parseInt(b,10),a],null)};
lca=function(a,b){return Hu(function(c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null);var f=$CLJS.J.g(kca,$CLJS.A(d));return $CLJS.n(f)?$CLJS.Hd(c,f)?hw(['Flag "',$CLJS.p.h($CLJS.A(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[d.substring(1),e+1,$CLJS.R.j(c,f,new $CLJS.P(null,2,5,$CLJS.Q,[!0,e],null))],
null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null)},new $CLJS.P(null,3,5,$CLJS.Q,[a,b,$CLJS.N],null))};
mca=function(a,b){var c=sx.h(a);$CLJS.n(function(){var d=$CLJS.Va(qw.h(c));return d?qw.h(b):d}())&&hw(['"@" is an illegal flag for format directive "',$CLJS.p.h(tx.h(a)),'"'].join(""),$CLJS.F(qw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(pw.h(c));return d?pw.h(b):d}())&&hw(['":" is an illegal flag for format directive "',$CLJS.p.h(tx.h(a)),'"'].join(""),$CLJS.F(pw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(ux.h(c));return d?(d=qw.h(b),$CLJS.n(d)?pw.h(b):d):d}())&&hw(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(tx.h(a)),'"'].join(""),function(){var d=$CLJS.F(pw.h(b),1),e=$CLJS.F(qw.h(b),1);return d<e?d:e}())};
oca=function(a,b,c,d){mca(a,c);$CLJS.D(b)>$CLJS.D(Sw.h(a))&&hw(fw(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.H([tx.h(a),$CLJS.D(b),$CLJS.D(Sw.h(a))])),$CLJS.hd($CLJS.A(b)));$CLJS.mh($CLJS.hf.j(function(e,f){var k=$CLJS.A(e);return null==k||$CLJS.Hd(nca,k)||$CLJS.E.g($CLJS.hd($CLJS.hd(f)),$CLJS.ab(k))?null:hw(["Parameter ",$CLJS.gh($CLJS.A(f)),' has bad type in directive "',$CLJS.p.h(tx.h(a)),'": ',$CLJS.p.h($CLJS.ab(k))].join(""),
$CLJS.hd(e))},b,Sw.h(a)));return $CLJS.gl.l($CLJS.H([$CLJS.eg.g($CLJS.N,$CLJS.ce(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.I(x,0,null);u.add(new $CLJS.P(null,2,5,$CLJS.Q,[z,new $CLJS.P(null,2,5,$CLJS.Q,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):
$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.I(m,0,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,new $CLJS.P(null,2,5,$CLJS.Q,[m,d],null)],null),k($CLJS.Lc(l)))}return null}},null,null)}(Sw.h(a))}())),$CLJS.fb(function(e,f){return $CLJS.Se.j($CLJS.R,e,f)},$CLJS.N,$CLJS.lf(function(e){return $CLJS.A($CLJS.F(e,1))},$CLJS.yu($CLJS.Xg(Sw.h(a)),b))),c]))};
qca=function(a,b){b=Hu(ica,new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null));a=$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);b=lca(b,c);$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);c=$CLJS.A(d);var f=$CLJS.J.g(pca,c.toUpperCase()),k=$CLJS.n(f)?oca(f,$CLJS.hf.g(jca,a),b,e):null;$CLJS.Va(c)&&hw("Format string ended in the middle of a directive",e);$CLJS.Va(f)&&hw(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.P(null,2,5,$CLJS.Q,[new ow(function(){var l=vx.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.E.g("\n",tx.h(f))&&$CLJS.Va(pw.h(k)))a:{var t=new $CLJS.P(null,2,5,$CLJS.Q,[" ","\t"],null);t=$CLJS.ud(t)?$CLJS.fh(t):$CLJS.ch([t]);for(var u=0;;){var v;(v=$CLJS.E.g(u,$CLJS.D(l)))||(v=$CLJS.F(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Va(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.P(null,2,5,$CLJS.Q,[l.substring(t),
m+t],null)}()],null)};wx=function(a,b){return new ow(function(c,d){Bw.l($CLJS.H([a]));return d},null,new $CLJS.h(null,1,[$CLJS.Yj,a],null),b,null,null,null)};zx=function(a,b){var c=rca(xx.h(yx.h(a)),$CLJS.Nt.h(a),b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new ow(Uw.h(a),yx.h(a),$CLJS.gl.l($CLJS.H([Sw.h(a),Aba(b,$CLJS.Nt.h(a))])),$CLJS.Nt.h(a),null,null,null),c],null)};
sca=function(a,b,c){return Hu(function(d){if($CLJS.td(d))return hw("No closing bracket found.",b);var e=$CLJS.A(d);d=$CLJS.B(d);if($CLJS.n($CLJS.Ax.h(xx.h(yx.h(e)))))e=zx(e,d);else if($CLJS.E.g($CLJS.Ax.h(a),tx.h(yx.h(e))))e=new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[Bx,Sw.h(e),null,d],null)],null);else{var f=$CLJS.Ot.h(xx.h(yx.h(e)));f=$CLJS.n(f)?pw.h(Sw.h(e)):f;e=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Mt,null,Sw.h(e),d],null)],null):
$CLJS.n($CLJS.Ot.h(xx.h(yx.h(e))))?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ot,null,null,d],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[e,d],null)}return e},c)};
rca=function(a,b,c){return $CLJS.hd(Hu(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);var k=sca(a,b,d);d=$CLJS.I(k,0,null);var l=$CLJS.I(k,1,null);k=$CLJS.I(l,0,null);var m=$CLJS.I(l,1,null),t=$CLJS.I(l,2,null);l=$CLJS.I(l,3,null);return $CLJS.E.g(k,Bx)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cx.l($CLJS.gf,$CLJS.H([e,$CLJS.Ee([$CLJS.n(f)?$CLJS.Mt:$CLJS.hx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null),jx,m])])),l],null)],null):$CLJS.E.g(k,$CLJS.Mt)?
$CLJS.n($CLJS.Mt.h(e))?hw('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Va($CLJS.Mt.h(a))?hw('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.E.g(Dx,$CLJS.Mt.h(a))&&$CLJS.y($CLJS.hx.h(e))?hw('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.E.g(Dx,$CLJS.Mt.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,2,[$CLJS.Mt,new $CLJS.P(null,1,5,
$CLJS.Q,[d],null),lx,t],null)])),!1,l],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.hx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!0,l],null)],null):$CLJS.E.g(k,$CLJS.Ot)?$CLJS.n(f)?hw('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Va(Ex.h(a))?hw('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.Cx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.hx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.h(null,1,[$CLJS.hx,$CLJS.Cf],null),!1,c],null)))};tca=function(a){return $CLJS.A(Hu(function(b){var c=$CLJS.A(b);b=$CLJS.B(b);var d=xx.h(yx.h(c));return $CLJS.n($CLJS.Ax.h(d))?zx(c,b):new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)},a))};
bw=function(a){var b=gw;gw=a;try{return tca($CLJS.A(Hu(function(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.P(null,2,5,$CLJS.Q,[wx(d,c),new $CLJS.P(null,2,5,$CLJS.Q,["",c+d.length],null)],null):0===e?qca(d.substring(1),c+1):new $CLJS.P(null,2,5,$CLJS.Q,[wx(d.substring(0,e),c),new $CLJS.P(null,2,5,$CLJS.Q,[d.substring(e),e+c],null)],null)},new $CLJS.P(null,2,5,$CLJS.Q,[a,0],null))))}finally{gw=
b}};ew=function(a,b){Gu(function(c,d){if(Rw(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=tw(Sw.h(c),d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=Iu(e);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=$CLJS.R.j(e,Tw,d);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Uw.h(c),new $CLJS.P(null,3,5,$CLJS.Q,[e,d,f],null))],null)},b,a);return null};
Gx=function(a){var b=$CLJS.A(a);b=Fx.h?Fx.h(b):Fx.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.E.g(2,$CLJS.D(a)):b)?($CLJS.ac($CLJS.Du,b),$CLJS.Pv($CLJS.hd(a)),!0):null};
Hx=function(a){if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var b=Rv,c=Mv;Rv+=1;Mv=0;try{Gv("[","]");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Pv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Du," ");Xv(Wv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Du,"...");break}Kv()}finally{Mv=c,Rv=b}}return null};
Ix=function(a){var b=$CLJS.yd(a)?null:function(){var m=new $CLJS.Gc(function(){return $CLJS.Hh},uca,$CLJS.Ig([$CLJS.wk,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.yk,$CLJS.ti,$CLJS.jk,$CLJS.ok],[!0,$CLJS.li,vca,"cljs/core.cljs",15,1,10543,10543,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dj],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.Hh)?$CLJS.Hh.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{c=Rv;b=Mv;Rv+=1;Mv=0;try{Gv(e,"}");e=0;for(var f=$CLJS.y(d);;){if($CLJS.Va($CLJS.Ma)||e<$CLJS.Ma){if(f){if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{d=Rv;var k=Mv;Rv+=1;Mv=0;try{Gv(null,null),$CLJS.Pv($CLJS.A($CLJS.A(f))),$CLJS.ac($CLJS.Du," "),Xv(Wv),Mv=0,$CLJS.Pv($CLJS.A($CLJS.B($CLJS.A(f)))),Kv()}finally{Mv=k,Rv=d}}if($CLJS.B(f)){$CLJS.ac($CLJS.Du,", ");Xv(Wv);d=e+1;var l=$CLJS.B(f);e=d;f=l;continue}}}else $CLJS.ac($CLJS.Du,
"...");break}Kv()}finally{Mv=b,Rv=c}}return null};Jx=function(a){return $CLJS.ac($CLJS.Du,$CLJS.Mh.l($CLJS.H([a])))};Mx=function(a){return a instanceof $CLJS.Rf?Kx:(null!=a?a.C&32768||$CLJS.Bc===a.ag||(a.C?0:$CLJS.$a($CLJS.ru,a)):$CLJS.$a($CLJS.ru,a))?Lx:a instanceof $CLJS.r?$CLJS.Gi:$CLJS.Dd(a)?$CLJS.Bj:$CLJS.xd(a)?$CLJS.hk:$CLJS.zd(a)?$CLJS.Jj:$CLJS.vd(a)?$CLJS.Zi:null==a?null:$CLJS.ci};
Nx=function(a){return $CLJS.zd(a)?new $CLJS.P(null,2,5,$CLJS.Q,["[","]"],null):new $CLJS.P(null,2,5,$CLJS.Q,["(",")"],null)};
wca=function(a){if($CLJS.wd(a)){var b=Nx(a),c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null),e=$CLJS.y(a),f=$CLJS.A(e),k=$CLJS.B(e);if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var l=Rv,m=Mv,t=Rv+1;Rv=t;Mv=0;try{Gv(c,d);(function(){var Na=Ox("~w~:i");return function(){function zb(Ya){var eb=null;if(0<arguments.length){eb=0;for(var Sa=Array(arguments.length-0);eb<Sa.length;)Sa[eb]=arguments[eb+0],++eb;eb=new $CLJS.w(Sa,0,null)}return Pa.call(this,eb)}function Pa(Ya){Ya=cw(Ya);return ew(Na,Ya)}zb.A=0;zb.B=function(Ya){Ya=
$CLJS.y(Ya);return Pa(Ya)};zb.l=Pa;return zb}()})()(f);for(var u=k;;)if($CLJS.y(u)){(function(){var Na=Ox(" ");return function(zb,Pa,Ya){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=cw(mb);return ew(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u," ",Na,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.A(u);if($CLJS.wd(v)){var x=Nx(v),z=$CLJS.I(x,0,null),C=$CLJS.I(x,1,null);if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var G=Rv,K=Mv,S=Rv+1;Rv=S;Mv=0;try{Gv(z,C);if($CLJS.E.g($CLJS.D(v),3)&&$CLJS.hd(v)instanceof $CLJS.M){var V=v,Z=$CLJS.I(V,0,null),ha=$CLJS.I(V,1,null),ra=$CLJS.I(V,2,null);(function(){var Na=Ox("~w ~w ");return function(zb,Pa,Ya){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+
0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=cw(mb);return ew(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~w ~w ",Na,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(Z,ha);$CLJS.wd(ra)?function(){var Na=$CLJS.zd(ra)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",zb="string"===typeof Na?Ox(Na):Na;return function(Pa,Ya,eb){return function(){function Sa(Ja){var bc=null;if(0<arguments.length){bc=0;for(var $d=
Array(arguments.length-0);bc<$d.length;)$d[bc]=arguments[bc+0],++bc;bc=new $CLJS.w($d,0,null)}return mb.call(this,bc)}function mb(Ja){Ja=cw(Ja);return ew(eb,Ja)}Sa.A=0;Sa.B=function(Ja){Ja=$CLJS.y(Ja);return mb(Ja)};Sa.l=mb;return Sa}()}(u,Na,zb,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(ra):$CLJS.Pv(ra)}else $CLJS.Se.g(function(){var Na=Ox("~w ~:i~@{~w~^ ~:_~}");return function(zb,Pa,Ya){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-
0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=cw(mb);return ew(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Na,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);Kv()}finally{Mv=K,Rv=G}}$CLJS.B(u)&&function(){var Na=Ox("~_");return function(zb,Pa,Ya){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=
arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=cw(mb);return ew(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~_",Na,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.Pv(v),$CLJS.B(u)&&function(){var Na=Ox("~:_");return function(zb,Pa,Ya){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,
Ja)}function Sa(mb){mb=cw(mb);return ew(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~:_",Na,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.B(u)}else break;Kv()}finally{Mv=m,Rv=l}}}else $CLJS.Pv(a)};
xca=function(a,b){$CLJS.y(a)&&($CLJS.n(b)?function(){var c=Ox(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=cw(f);return ew(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()():function(){var c=Ox(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<
l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=cw(f);return ew(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()(),function(){var c=Ox("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=cw(f);return ew(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;
return d}()}()(a))};yca=function(a){$CLJS.y(a)&&function(){var b=Ox(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.w(k,0,null)}return d.call(this,f)}function d(e){e=cw(e);return ew(b,e)}c.A=0;c.B=function(e){e=$CLJS.y(e);return d(e)};c.l=d;return c}()}()(a)};
Qx=function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d="string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var f=
Rv,k=Mv;Rv+=1;Mv=0;try{Gv("(",")"),function(){var l=Ox("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=cw(u);return ew(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=Ox(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=cw(u);return ew(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=Ox(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=cw(u);return ew(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.zd($CLJS.A(e))?xca(e,$CLJS.n(c)?c:d):yca(e),Kv()}finally{Mv=k,Rv=f}}return null}return Px(a)};
zca=function(a){if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var b=Rv,c=Mv;Rv+=1;Mv=0;try{Gv("[","]");for(var d=0;;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if($CLJS.y(a)){if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var e=Rv,f=Mv;Rv+=1;Mv=0;try{Gv(null,null),$CLJS.Pv($CLJS.A(a)),$CLJS.B(a)&&($CLJS.ac($CLJS.Du," "),Xv(Uv),$CLJS.Pv($CLJS.hd(a))),Kv()}finally{Mv=f,Rv=e}}if($CLJS.B($CLJS.Lc(a))){$CLJS.ac($CLJS.Du," ");Xv(Wv);e=d+1;var k=$CLJS.B($CLJS.Lc(a));d=e;a=k;continue}}}else $CLJS.ac($CLJS.Du,"...");
break}Kv()}finally{Mv=c,Rv=b}}};
Rx=function(a){var b=$CLJS.A(a);if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var c=Rv,d=Mv;Rv+=1;Mv=0;try{Gv("(",")"),$CLJS.B(a)&&$CLJS.zd($CLJS.hd(a))?(function(){var e=Ox("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=cw(l);return ew(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()(b),zca($CLJS.hd(a)),function(){var e=
Ox(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=cw(l);return ew(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()($CLJS.B($CLJS.Lc(a)))):Px(a),Kv()}finally{Mv=d,Rv=c}}return null};
Px=function(a){if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var b=Rv,c=Mv;Rv+=1;Mv=0;try{Gv("(",")");aw(Yv,1);for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Pv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Du," ");Xv(Wv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Du,"...");break}Kv()}finally{Mv=c,Rv=b}}return null};$CLJS.ne.prototype.Lc=$CLJS.va(2,function(){return $CLJS.Va(this.Rb)});$CLJS.cf.prototype.Lc=$CLJS.va(1,function(){return!1});
$CLJS.Jh.prototype.Lc=$CLJS.va(0,function(){return $CLJS.Va(this.f)});$CLJS.Du=null;Sx=function Sx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Sx.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};Sx.l=function(a){a=$CLJS.y(a);for(var b=$CLJS.ec($CLJS.gg);;)if(a){var c=$CLJS.B(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.A(a))].join(""));var d=$CLJS.B(c);b=$CLJS.hg.j(b,$CLJS.A(a),$CLJS.A(c));a=d}else return $CLJS.gc(b)};
Sx.A=0;Sx.B=function(a){return this.l($CLJS.y(a))};$CLJS.Cx=function Cx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Cx.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.Cx.l=function(a,b){if($CLJS.n($CLJS.Re($CLJS.Td,b))){var c=function(d,e){var f=$CLJS.Eb(e),k=$CLJS.Fb(e);if($CLJS.Hd(d,f)){e=$CLJS.R.j;var l=$CLJS.J.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.R,d,f,k)}else d=$CLJS.R.j(d,f,k);return d};return $CLJS.Qd(function(d,e){return $CLJS.fb(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.y(e))},b)}return null};$CLJS.Cx.A=1;$CLJS.Cx.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.Gw=function Gw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Gw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};$CLJS.Gw.l=function(a){return $CLJS.Eh(a,$CLJS.R.j($CLJS.Oa(),$CLJS.Ga,!1))};$CLJS.Gw.A=0;$CLJS.Gw.B=function(a){return this.l($CLJS.y(a))};Zu=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);Tx=new $CLJS.M(null,"relative-to","relative-to",-470100051);mv=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
ex=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);Aca=new $CLJS.r(null,"when-let","when-let",-1383043480,null);Uv=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.Zv=new $CLJS.M(null,"current","current",-1088038603);Bca=new $CLJS.r(null,"-\x3e","-\x3e",-2139605430,null);Cca=new $CLJS.r(null,"..","..",-300507420,null);bv=new $CLJS.M(null,"end-pos","end-pos",-1643883926);kv=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);Kx=new $CLJS.M(null,"queue","queue",1455835879);
Dca=new $CLJS.r(null,"if-let","if-let",1803593690,null);Iv=new $CLJS.M(null,"end","end",-268185958);sw=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);sv=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);Eca=new $CLJS.r(null,"defmacro","defmacro",2054157304,null);Fca=new $CLJS.r(null,"when-first","when-first",821699168,null);Fv=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);Gca=new $CLJS.r(null,"binding","binding",-2114503176,null);
xx=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);wv=new $CLJS.M(null,"writing","writing",-1486865108);rw=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);Dv=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);gx=new $CLJS.M(null,"selector","selector",762528866);Hca=new $CLJS.r(null,"struct","struct",325972931,null);lv=new $CLJS.M(null,"indent","indent",-148200125);Ica=new $CLJS.r(null,"loop","loop",1244978678,null);
nx=new $CLJS.M(null,"max-columns","max-columns",1742323262);ux=new $CLJS.M(null,"both","both",-393648840);Ux=new $CLJS.M(null,"colnum","colnum",2023796854);Jca=new $CLJS.r(null,"doseq","doseq",221164135,null);$CLJS.Vx=new $CLJS.M(null,"length","length",588987862);Ow=new $CLJS.M(null,"char-format","char-format",-1016499218);Wx=new $CLJS.M(null,"prefix","prefix",-265908465);Qw=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);Hv=new $CLJS.M(null,"suffix","suffix",367373057);
qw=new $CLJS.M(null,"at","at",1476951349);Yu=new $CLJS.M(null,"nl-t","nl-t",-1608382114);tx=new $CLJS.M(null,"directive","directive",793559132);Hba=new $CLJS.M(null,"buffer-level","buffer-level",928864731);Tv=new $CLJS.M(null,"mandatory","mandatory",542802336);Bv=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);ev=new $CLJS.M(null,"done-nl","done-nl",-381024340);Kca=new $CLJS.r(null,"condp","condp",1054325175,null);jw=new $CLJS.M(null,"seq","seq",-1817803783);
Lca=new $CLJS.r(null,"defn","defn",-126010802,null);zw=new $CLJS.M(null,"colinc","colinc",-584873385);Pw=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Bx=new $CLJS.M(null,"right-bracket","right-bracket",951856080);Mca=new $CLJS.M(null,"radix","radix",857016463);Dx=new $CLJS.M(null,"first","first",-644103046);Gba=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.Ev=new $CLJS.M(null,"start","start",-355208981);Nca=new $CLJS.r("cljs.core","unquote","cljs.core/unquote",1013085760,null);
Oca=new $CLJS.r(null,"defn-","defn-",1097765044,null);Pca=new $CLJS.r("cljs.core","deref","cljs.core/deref",1901963335,null);Yv=new $CLJS.M(null,"block","block",664686210);Qca=new $CLJS.r(null,"when","when",1064114221,null);lx=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.Xx=new $CLJS.M(null,"count","count",2139924085);$CLJS.Ax=new $CLJS.M(null,"right","right",-452581833);Wv=new $CLJS.M(null,"linear","linear",872268697);Rca=new $CLJS.r(null,"when-not","when-not",-1223136340,null);
Aw=new $CLJS.M(null,"padchar","padchar",2018584530);yw=new $CLJS.M(null,"mincol","mincol",1230695445);Sca=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);gv=new $CLJS.M(null,"miser-width","miser-width",-1310049437);Tca=new $CLJS.r(null,"with-local-vars","with-local-vars",837642072,null);vca=new $CLJS.r(null,"lift-ns","lift-ns",602311926,null);Ex=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);tv=new $CLJS.M(null,"buffering","buffering",-876713613);
Yx=new $CLJS.M(null,"arg1","arg1",951899358);Tw=new $CLJS.M(null,"base-args","base-args",-1268706822);Zx=new $CLJS.M(null,"arg3","arg3",-1486822496);$x=new $CLJS.M(null,"arg2","arg2",1729550917);Ew=new $CLJS.M(null,"commainterval","commainterval",-1980061083);Uca=new $CLJS.M(null,"right-margin","right-margin",-810413306);vv=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);Vca=new $CLJS.r(null,"with-open","with-open",172119667,null);
uca=new $CLJS.r("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);Wca=new $CLJS.M(null,"lines","lines",-700165781);$v=new $CLJS.M(null,"indent-t","indent-t",528318969);jx=new $CLJS.M(null,"right-params","right-params",-1790676237);pw=new $CLJS.M(null,"colon","colon",-965200945);Xca=new $CLJS.r(null,"if-not","if-not",-265415609,null);Uw=new $CLJS.M(null,"func","func",-238706040);$CLJS.ay=new $CLJS.M(null,"last","last",1105735132);Lx=new $CLJS.M(null,"deref","deref",-145586795);
Yca=new $CLJS.r(null,"dotimes","dotimes",-818708397,null);ix=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);Zca=new $CLJS.r(null,"cond","cond",1606708055,null);xw=new $CLJS.M(null,"minpad","minpad",323570901);jv=new $CLJS.M(null,"logical-block","logical-block",-581022564);$ca=new $CLJS.r(null,"struct-map","struct-map",-1387540878,null);Jv=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);by=new $CLJS.M(null,"stream","stream",1534941648);
Sw=new $CLJS.M(null,"params","params",710516235);ada=new $CLJS.M(null,"circle","circle",1903212362);cv=new $CLJS.M(null,"start-pos","start-pos",668789086);sx=new $CLJS.M(null,"flags","flags",1775418075);Vv=new $CLJS.M(null,"fill","fill",883462889);Fba=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);vx=new $CLJS.M(null,"generator-fn","generator-fn",811851656);hv=new $CLJS.M(null,"start-col","start-col",668080143);Zw=new $CLJS.M(null,"w","w",354169001);yx=new $CLJS.M(null,"def","def",-1043430536);
$CLJS.ax=new $CLJS.M(null,"k","k",-2146297393);dx=new $CLJS.M(null,"e","e",1381269198);$w=new $CLJS.M(null,"d","d",1972142424);bx=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);mx=new $CLJS.M(null,"min-remaining","min-remaining",962687677);Fw=new $CLJS.M(null,"commachar","commachar",652859327);cy=new $CLJS.M(null,"section","section",-300141526);dy=new $CLJS.M(null,"pretty","pretty",-1916372486);bda=new $CLJS.r(null,"let","let",358118826,null);Qu=new $CLJS.M(null,"parent","parent",-878878779);
$CLJS.hx=new $CLJS.M(null,"clauses","clauses",1454841241);cda=new $CLJS.r(null,"defonce","defonce",-1681484013,null);Nu=new $CLJS.M(null,"base","base",185279322);Xu=new $CLJS.M(null,"type-tag","type-tag",-1873863267);dda=new $CLJS.r(null,"locking","locking",1542862874,null);uv=new $CLJS.M(null,"pos","pos",-864607220);eda=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.Mu=new $CLJS.M(null,"cur","cur",1153190599);var Bw=function Bw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Bw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)},Ov,Av,fy,gy,hy,vw,uw,Rv,Mv,iy;Bw.l=function(a){return $CLJS.ac($CLJS.Du,$CLJS.Se.g($CLJS.Gw,a))};Bw.A=0;Bw.B=function(a){return this.l($CLJS.y(a))};var Cu=function Cu(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Cu.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};
Cu.l=function(a){return $CLJS.ac($CLJS.Du,$CLJS.Se.g($CLJS.Mh,a))};Cu.A=0;Cu.B=function(a){return this.l($CLJS.y(a))};$CLJS.g=Ou.prototype;$CLJS.g.P=function(a,b){return new Ou(this.Ca,this.Nf,this.Yc,b)};$CLJS.g.O=function(){return this.bi};$CLJS.g.Qb=function(){return this.Yc};$CLJS.g.rc=function(){return su(this.Ca)};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?Lu(this,$CLJS.Mu,$CLJS.Ku(this,$CLJS.Mu)+$CLJS.D(b)):(Lu(this,$CLJS.Mu,$CLJS.D(b)-a-1),Lu(this,$CLJS.mj,$CLJS.Ku(this,$CLJS.mj)+$CLJS.D($CLJS.lf(function(c){return $CLJS.E.g(c,"\n")},b)))),$CLJS.ac($CLJS.Ku(this,Nu),b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return Bba(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=Pu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.tb;case "indent":return this.sb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ab;case "suffix":return this.Db;case "logical-block-callback":return this.xb;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Qu,this.parent],null),new $CLJS.P(null,2,5,$CLJS.Q,[cy,this.Cb],null),new $CLJS.P(null,2,5,$CLJS.Q,[hv,this.tb],null),new $CLJS.P(null,2,5,$CLJS.Q,[lv,this.sb],null),new $CLJS.P(null,2,5,$CLJS.Q,[ev,this.vb],null),new $CLJS.P(null,2,5,$CLJS.Q,[mv,this.wb],null),new $CLJS.P(null,2,5,$CLJS.Q,
[Wx,this.prefix],null),new $CLJS.P(null,2,5,$CLJS.Q,[kv,this.Ab],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hv,this.Db],null),new $CLJS.P(null,2,5,$CLJS.Q,[Dv,this.xb],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Gt(this,10,new $CLJS.P(null,10,5,$CLJS.Q,[Qu,cy,hv,lv,ev,mv,Wx,kv,Hv,Dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.D(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.parent,b.parent)&&$CLJS.E.g(this.Cb,b.Cb)&&$CLJS.E.g(this.tb,b.tb)&&$CLJS.E.g(this.sb,b.sb)&&$CLJS.E.g(this.vb,b.vb)&&$CLJS.E.g(this.wb,b.wb)&&$CLJS.E.g(this.prefix,b.prefix)&&$CLJS.E.g(this.Ab,b.Ab)&&$CLJS.E.g(this.Db,b.Db)&&$CLJS.E.g(this.xb,b.xb)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,10,[Hv,null,lv,null,Qu,null,cy,null,ev,null,hv,null,Wx,null,kv,null,Dv,null,mv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Pu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Qu,b):$CLJS.he.call(null,Qu,b))?new Pu(c,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(cy,b):$CLJS.he.call(null,cy,b))?new Pu(this.parent,c,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(hv,b):$CLJS.he.call(null,hv,b))?new Pu(this.parent,this.Cb,c,this.sb,this.vb,this.wb,this.prefix,this.Ab,
this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(lv,b):$CLJS.he.call(null,lv,b))?new Pu(this.parent,this.Cb,this.tb,c,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ev,b):$CLJS.he.call(null,ev,b))?new Pu(this.parent,this.Cb,this.tb,this.sb,c,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(mv,b):$CLJS.he.call(null,mv,b))?new Pu(this.parent,this.Cb,this.tb,this.sb,this.vb,c,this.prefix,
this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Wx,b):$CLJS.he.call(null,Wx,b))?new Pu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,c,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(kv,b):$CLJS.he.call(null,kv,b))?new Pu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,c,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Hv,b):$CLJS.he.call(null,Hv,b))?new Pu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,
this.prefix,this.Ab,c,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Dv,b):$CLJS.he.call(null,Dv,b))?new Pu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,c,this.S,this.G,null):new Pu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.Yf(Qu,this.parent),new $CLJS.Yf(cy,this.Cb),new $CLJS.Yf(hv,this.tb),new $CLJS.Yf(lv,this.sb),new $CLJS.Yf(ev,this.vb),new $CLJS.Yf(mv,this.wb),new $CLJS.Yf(Wx,this.prefix),new $CLJS.Yf(kv,this.Ab),new $CLJS.Yf(Hv,this.Db),new $CLJS.Yf(Dv,this.xb)],null),this.G))};$CLJS.g.P=function(a,b){return new Pu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Su.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "data":return this.data;case "trailing-white-space":return this.ac;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Xu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qj,this.data],null),new $CLJS.P(null,2,5,$CLJS.Q,[Zu,this.ac],null),new $CLJS.P(null,2,5,$CLJS.Q,[cv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Gt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Xu,$CLJS.qj,Zu,cv,bv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.data,b.data)&&$CLJS.E.g(this.ac,b.ac)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,5,[bv,null,Zu,null,Xu,null,cv,null,$CLJS.qj,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Su(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Xu,b):$CLJS.he.call(null,Xu,b))?new Su(c,this.data,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.qj,b):$CLJS.he.call(null,$CLJS.qj,b))?new Su(this.ca,c,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Zu,b):$CLJS.he.call(null,Zu,b))?new Su(this.ca,this.data,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(cv,b):$CLJS.he.call(null,cv,b))?new Su(this.ca,this.data,
this.ac,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Su(this.ca,this.data,this.ac,this.ba,c,this.S,this.G,null):new Su(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(Xu,this.ca),new $CLJS.Yf($CLJS.qj,this.data),new $CLJS.Yf(Zu,this.ac),new $CLJS.Yf(cv,this.ba),new $CLJS.Yf(bv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Su(this.ca,this.data,this.ac,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Tu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Xu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,this.type],null),new $CLJS.P(null,2,5,$CLJS.Q,[jv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[cv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Gt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Xu,$CLJS.lj,jv,cv,bv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.type,b.type)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,5,[bv,null,$CLJS.lj,null,jv,null,Xu,null,cv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Tu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Xu,b):$CLJS.he.call(null,Xu,b))?new Tu(c,this.type,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.lj,b):$CLJS.he.call(null,$CLJS.lj,b))?new Tu(this.ca,c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(jv,b):$CLJS.he.call(null,jv,b))?new Tu(this.ca,this.type,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(cv,b):$CLJS.he.call(null,cv,b))?new Tu(this.ca,this.type,
this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Tu(this.ca,this.type,this.ea,this.ba,c,this.S,this.G,null):new Tu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(Xu,this.ca),new $CLJS.Yf($CLJS.lj,this.type),new $CLJS.Yf(jv,this.ea),new $CLJS.Yf(cv,this.ba),new $CLJS.Yf(bv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Tu(this.ca,this.type,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Uu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Xu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[jv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[cv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Gt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Xu,jv,cv,bv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[bv,null,jv,null,Xu,null,cv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Uu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Xu,b):$CLJS.he.call(null,Xu,b))?new Uu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(jv,b):$CLJS.he.call(null,jv,b))?new Uu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(cv,b):$CLJS.he.call(null,cv,b))?new Uu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Uu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Uu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Xu,this.ca),new $CLJS.Yf(jv,this.ea),new $CLJS.Yf(cv,this.ba),new $CLJS.Yf(bv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Uu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Vu.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Xu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[jv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[cv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Gt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Xu,jv,cv,bv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[bv,null,jv,null,Xu,null,cv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Vu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Xu,b):$CLJS.he.call(null,Xu,b))?new Vu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(jv,b):$CLJS.he.call(null,jv,b))?new Vu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(cv,b):$CLJS.he.call(null,cv,b))?new Vu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Vu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Vu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Xu,this.ca),new $CLJS.Yf(jv,this.ea),new $CLJS.Yf(cv,this.ba),new $CLJS.Yf(bv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Vu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Wu.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "relative-to":return this.Wb;case "offset":return this.offset;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Xu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[jv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tx,this.Wb],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,this.offset],null),new $CLJS.P(null,2,5,$CLJS.Q,[cv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Gt(this,6,new $CLJS.P(null,6,5,$CLJS.Q,[Xu,jv,Tx,$CLJS.Nt,cv,bv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.Wb,b.Wb)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,6,[$CLJS.Nt,null,bv,null,Tx,null,jv,null,Xu,null,cv,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Wu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Xu,b):$CLJS.he.call(null,Xu,b))?new Wu(c,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(jv,b):$CLJS.he.call(null,jv,b))?new Wu(this.ca,c,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Tx,b):$CLJS.he.call(null,Tx,b))?new Wu(this.ca,this.ea,c,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Nt,b):$CLJS.he.call(null,$CLJS.Nt,
b))?new Wu(this.ca,this.ea,this.Wb,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(cv,b):$CLJS.he.call(null,cv,b))?new Wu(this.ca,this.ea,this.Wb,this.offset,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Wu(this.ca,this.ea,this.Wb,this.offset,this.ba,c,this.S,this.G,null):new Wu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.Yf(Xu,this.ca),new $CLJS.Yf(jv,this.ea),new $CLJS.Yf(Tx,this.Wb),new $CLJS.Yf($CLJS.Nt,this.offset),new $CLJS.Yf(cv,this.ba),new $CLJS.Yf(bv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Wu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};
var $u=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("cljs.pprint","write-token"),function(f,k){return Xu.h(k)},e,a,b,c,d)}();
$u.m(null,Fv,function(a,b){var c=Dv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.Ev):c.call(null,$CLJS.Ev));b=jv.h(b);c=Wx.h(b);$CLJS.n(c)&&$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.Ku(Nu.h($CLJS.q($CLJS.q(a))),$CLJS.Mu);$CLJS.Ye(hv.h(b),a);return $CLJS.Ye(lv.h(b),a)});$u.m(null,Jv,function(a,b){var c=Dv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(Iv):c.call(null,Iv));b=Hv.h(jv.h(b));return $CLJS.n(b)?$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),b):null});
$u.m(null,$v,function(a,b){var c=jv.h(b),d=lv.h(c),e=$CLJS.Nt.h(b);b=Tx.h(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Yv,b):$CLJS.E.call(null,Yv,b)))a=$CLJS.q(hv.h(c));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.Zv,b):$CLJS.E.call(null,$CLJS.Zv,b)))a=$CLJS.Ku(Nu.h($CLJS.q($CLJS.q(a))),$CLJS.Mu);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.Ye(d,e+a)});$u.m(null,vv,function(a,b){return $CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),$CLJS.qj.h(b))});
$u.m(null,Yu,function(a,b){var c=$CLJS.E.g($CLJS.lj.h(b),Tv);c||(c=(c=!$CLJS.E.g($CLJS.lj.h(b),Vv))?$CLJS.q(ev.h(jv.h(b))):c);$CLJS.n(c)?nv.g?nv.g(a,b):nv.call(null,a,b):(b=Zu.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.ac(Nu.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Oh.v($CLJS.q(a),$CLJS.R,Zu,null)});
var ey=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.lj.h(f)},e,a,b,c,d)}();ey.m(null,Wv,function(a,b,c){a=jv.h(a);return fv(b,a,c)});ey.m(null,Uv,function(a,b,c){a=jv.h(a);return iv(b,a,c)});
ey.m(null,Vv,function(a,b,c,d){a=jv.h(a);var e=$CLJS.q(mv.h(a));return $CLJS.n(e)?e:(d=!dv(b,d))?d:iv(b,a,c)});ey.m(null,Tv,function(){return!0});
var ov=function ov(a,b){var d=Eba(b);b=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);$CLJS.n(b)&&av(a,b,!1);if($CLJS.n(e)){d=Cba(e);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=$CLJS.A(e),k=ey.v(f,a,b,Dba(e));$CLJS.n(k)&&(nv(a,f),e=$CLJS.B(e));dv(a,e)?a=e:(e=ov.g?ov.g(a,b):ov.call(null,a,b),$CLJS.E.g(e,b)?(av(a,b,!1),a=d):a=$CLJS.eg.g($CLJS.Cf,$CLJS.gf.g(e,d)));return a}return null};$CLJS.g=yv.prototype;$CLJS.g.P=function(a,b){return new yv(this.Ca,this.Nf,this.li,this.mh,this.Yc,b)};$CLJS.g.O=function(){return this.ci};
$CLJS.g.Qb=function(){return this.Yc};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a))){var c=xv(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.xi.h($CLJS.q($CLJS.q(this)));if($CLJS.E.g(d,wv))return rv(this),$CLJS.ac(Nu.h($CLJS.q($CLJS.q(this))),b),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,Zu,a);d=uv.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.D(c);$CLJS.Oh.v($CLJS.q(this),$CLJS.R,uv,c);return qv(this,new Su(vv,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,
a):$CLJS.E.call(null,Number,a)))return $CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(this))),wv)?(rv(this),b=$CLJS.ac(Nu.h($CLJS.q($CLJS.q(this))),b)):$CLJS.E.g(b,"\n")?b=xv(this,"\n"):(a=uv.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Oh.v($CLJS.q(this),$CLJS.R,uv,c),b=vu(b),b=qv(this,new Su(vv,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.rc=function(){this.uf(null);return su(Nu.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.uf=function(){return $CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(this))),tv)?(av(this,$CLJS.Lt.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,$CLJS.Lt,$CLJS.Cf)):rv(this)};$CLJS.Nv=!0;Ov=null;$CLJS.zv=72;Av=40;fy=null;gy=null;hy=null;vw=null;uw=10;Rv=0;Mv=null;iy=function iy(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return iy.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
iy.l=function(a,b){var c=$CLJS.gl.l($CLJS.H([new $CLJS.h(null,1,[by,!0],null),$CLJS.Se.g(Sx,b)]));b=uw;var d=gy,e=$CLJS.Ma,f=$CLJS.qh,k=fy,l=Av,m=Ov,t=$CLJS.Nv,u=vw,v=$CLJS.Ha,x=$CLJS.zv,z=hy,C=Nu.g(c,uw),G=ada.g(c,gy),K=$CLJS.Vx.g(c,$CLJS.Ma),S=$CLJS.Pt.g(c,$CLJS.qh),V=Wca.g(c,fy),Z=gv.g(c,Av),ha=$CLJS.Ni.g(c,Ov),ra=dy.g(c,$CLJS.Nv),Na=Mca.g(c,vw),zb=$CLJS.Ga.g(c,$CLJS.Ha),Pa=Uca.g(c,$CLJS.zv),Ya=eda.g(c,hy);uw=C;gy=G;$CLJS.Ma=K;$CLJS.qh=S;fy=V;Av=Z;Ov=ha;$CLJS.Nv=ra;vw=Na;$CLJS.Ha=zb;$CLJS.zv=Pa;
hy=Ya;try{var eb=new $CLJS.Ea,Sa=$CLJS.Hd(c,by)?by.h(c):!0,mb=!0===Sa||null==Sa?new $CLJS.sc(eb):Sa;if($CLJS.n($CLJS.Nv)){var Ja=$CLJS.Va($CLJS.Lv(mb));c=$CLJS.Du;$CLJS.Du=Ja?$CLJS.Cv(mb):mb;try{$CLJS.Pv(a),$CLJS.Ju()}finally{$CLJS.Du=c}}else{Ja=$CLJS.Du;$CLJS.Du=mb;try{Cu.call(null,a)}finally{$CLJS.Du=Ja}}!0===Sa&&$CLJS.uh($CLJS.p.h(eb));return null==Sa?$CLJS.p.h(eb):null}finally{hy=z,$CLJS.zv=x,$CLJS.Ha=v,vw=u,$CLJS.Nv=t,Ov=m,Av=l,fy=k,$CLJS.qh=f,$CLJS.Ma=e,gy=d,uw=b}};iy.A=1;
iy.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var gw=null;$CLJS.g=iw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "seq":return this.uc;case "rest":return this.pb;case "pos":return this.$b;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[jw,this.uc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,this.pb],null),new $CLJS.P(null,2,5,$CLJS.Q,[uv,this.$b],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Gt(this,3,new $CLJS.P(null,3,5,$CLJS.Q,[jw,$CLJS.Oi,uv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.uc,b.uc)&&$CLJS.E.g(this.pb,b.pb)&&$CLJS.E.g(this.$b,b.$b)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,3,[uv,null,jw,null,$CLJS.Oi,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new iw(this.uc,this.pb,this.$b,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(jw,b):$CLJS.he.call(null,jw,b))?new iw(c,this.pb,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Oi,b):$CLJS.he.call(null,$CLJS.Oi,b))?new iw(this.uc,c,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(uv,b):$CLJS.he.call(null,uv,b))?new iw(this.uc,this.pb,c,this.S,this.G,null):new iw(this.uc,this.pb,this.$b,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.Yf(jw,this.uc),new $CLJS.Yf($CLJS.Oi,this.pb),new $CLJS.Yf(uv,this.$b)],null),this.G))};$CLJS.g.P=function(a,b){return new iw(this.uc,this.pb,this.$b,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=ow.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "func":return this.dc;case "def":return this.cc;case "params":return this.kc;case "offset":return this.offset;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Uw,this.dc],null),new $CLJS.P(null,2,5,$CLJS.Q,[yx,this.cc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sw,this.kc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nt,this.offset],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Gt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Uw,yx,Sw,$CLJS.Nt],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.dc,b.dc)&&$CLJS.E.g(this.cc,b.cc)&&$CLJS.E.g(this.kc,b.kc)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Nt,null,Uw,null,Sw,null,yx,null],null),null),b)?$CLJS.Hk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new ow(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.Fe($CLJS.Hk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Uw,b):$CLJS.he.call(null,Uw,b))?new ow(c,this.cc,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(yx,b):$CLJS.he.call(null,yx,b))?new ow(this.dc,c,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Sw,b):$CLJS.he.call(null,Sw,b))?new ow(this.dc,this.cc,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Nt,b):$CLJS.he.call(null,$CLJS.Nt,b))?new ow(this.dc,this.cc,this.kc,c,this.S,
this.G,null):new ow(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Uw,this.dc),new $CLJS.Yf(yx,this.cc),new $CLJS.Yf(Sw,this.kc),new $CLJS.Yf($CLJS.Nt,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new ow(this.dc,this.cc,this.kc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};
var Jba=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),Iw=new $CLJS.P(null,20,5,$CLJS.Q,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),jy=new $CLJS.P(null,20,5,$CLJS.Q,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),Jw=new $CLJS.P(null,10,5,$CLJS.Q,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),fda=new $CLJS.P(null,10,5,$CLJS.Q,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),Lw=new $CLJS.P(null,22,5,$CLJS.Q," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),gda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),hda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),Lba=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);ox.prototype.P=function(a,b){return new ox(this.Ca,b)};ox.prototype.O=function(){return this.di};ox.prototype.rc=function(){return su(this.Ca)};
ox.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,vu(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};px.prototype.P=function(a,b){return new px(this.Ca,b)};px.prototype.O=function(){return this.ei};px.prototype.rc=function(){return su(this.Ca)};
px.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toUpperCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,vu(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};qx.prototype.P=function(a,b){return new qx(this.Ca,this.Jd,b)};qx.prototype.O=function(){return this.fi};qx.prototype.rc=function(){return su(this.Ca)};
qx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,cca(b.toLowerCase(),$CLJS.q(this.Jd))),0<b.length?$CLJS.Ye(this.Jd,$CLJS.Aa($CLJS.F(b,$CLJS.D(b)-1))):null;if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=vu(b),a=$CLJS.n($CLJS.q(this.Jd))?b.toUpperCase():b,$CLJS.ac(this.Ca,a),$CLJS.Ye(this.Jd,$CLJS.Aa(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
rx.prototype.P=function(a,b){return new rx(this.Ca,this.vd,b)};rx.prototype.O=function(){return this.gi};rx.prototype.rc=function(){return su(this.Ca)};
rx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Va($CLJS.q(this.vd))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.ac(this.Ca,[b.substring(0,a),$CLJS.F(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.Ye(this.vd,!0)):$CLJS.ac(this.Ca,b)):$CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=vu(b),a=$CLJS.Va($CLJS.q(this.vd)),
$CLJS.n(a?qu(b):a)?($CLJS.Ye(this.vd,!0),$CLJS.ac(this.Ca,b.toUpperCase())):$CLJS.ac(this.Ca,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var pca=$CLJS.Ig("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[tx,"A",Sw,new $CLJS.h(null,4,[yw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){return Cw($CLJS.Gw,a,b)}}],null),new $CLJS.h(null,
5,[tx,"S",Sw,new $CLJS.h(null,4,[yw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){return Cw($CLJS.Mh,a,b)}}],null),new $CLJS.h(null,5,[tx,"D",Sw,new $CLJS.h(null,4,[yw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Aw,new $CLJS.P(null,
2,5,$CLJS.Q,[" ",String],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){return Hw(10,a,b)}}],null),new $CLJS.h(null,5,[tx,"B",Sw,new $CLJS.h(null,4,[yw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Ew,new $CLJS.P(null,
2,5,$CLJS.Q,[3,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){return Hw(2,a,b)}}],null),new $CLJS.h(null,5,[tx,"O",Sw,new $CLJS.h(null,4,[yw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,
null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){return Hw(8,a,b)}}],null),new $CLJS.h(null,5,[tx,"X",Sw,new $CLJS.h(null,4,[yw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){return Hw(16,a,b)}}],null),new $CLJS.h(null,
5,[tx,"R",Sw,new $CLJS.h(null,5,[Nu,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),yw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,$CLJS.N,vx,function(a){return $CLJS.n($CLJS.A(Nu.h(a)))?function(b,c){return Hw(Nu.h(b),b,c)}:$CLJS.n(function(){var b=qw.h(a);return $CLJS.n(b)?
pw.h(a):b}())?function(b,c){return Nw(gda,c)}:$CLJS.n(qw.h(a))?function(b,c){return Nw(hda,c)}:$CLJS.n(pw.h(a))?function(b,c){b=kw(c);c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if($CLJS.E.g(0,c))Bw.l($CLJS.H(["zeroth"]));else{var d=Dw(1E3,0>c?-c:c);if($CLJS.D(d)<=$CLJS.D(Lw)){var e=$CLJS.hf.g(Kw,$CLJS.Bu(1,d));e=Mw(e,1);var f=$CLJS.id(d);d=$CLJS.Vd(f,100);f=wu(f,100);var k=0<d?[$CLJS.p.h($CLJS.F(Iw,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.F(jy,f);else{t=$CLJS.Vd(f,10);
var u=wu(f,10);t=0<t&&!(0<u)?$CLJS.F(fda,t):[$CLJS.p.h(0<t?$CLJS.F(Jw,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.F(jy,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");Bw.l($CLJS.H([[0>c?"minus ":null,$CLJS.td(e)||$CLJS.td(d)?$CLJS.td(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else Hw(10,new $CLJS.h(null,5,[yw,0,Aw," ",Fw,",",Ew,3,pw,!0],null),cw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null))),e=wu(c,100),c=11<e||19>e,e=wu(e,10),Bw.l($CLJS.H([1===e&&c?
"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=kw(c);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.E.g(0,b))Bw.l($CLJS.H(["zero"]));else{var d=Dw(1E3,0>b?-b:b);$CLJS.D(d)<=$CLJS.D(Lw)?(d=$CLJS.hf.g(Kw,d),d=Mw(d,0),Bw.l($CLJS.H([[0>b?"minus ":null,d].join("")]))):Hw(10,new $CLJS.h(null,5,[yw,0,Aw," ",Fw,",",Ew,3,pw,!0],null),cw(new $CLJS.P(null,1,5,$CLJS.Q,[b],null)))}return c}}],null),new $CLJS.h(null,5,[tx,"P",Sw,$CLJS.N,sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,
null,ux,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){b=$CLJS.n(pw.h(a))?mw(b,-1):b;a=$CLJS.n(qw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,["y","ies"],null):new $CLJS.P(null,2,5,$CLJS.Q,["","s"],null);var c=kw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);Bw.l($CLJS.H([$CLJS.E.g(b,1)?$CLJS.A(a):$CLJS.hd(a)]));return c}}],null),new $CLJS.h(null,5,[tx,"C",Sw,new $CLJS.h(null,1,[Ow,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,
ux,null],null),null),xx,$CLJS.N,vx,function(a){return $CLJS.n(pw.h(a))?Mba:$CLJS.n(qw.h(a))?Nba:Oba}],null),new $CLJS.h(null,5,[tx,"F",Sw,new $CLJS.h(null,5,[Zw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$w,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.ax,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),bx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,1,[qw,null],null),null),xx,$CLJS.N,vx,
function(){return cx}],null),new $CLJS.h(null,5,[tx,"E",Sw,new $CLJS.h(null,7,[Zw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$w,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.ax,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),bx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),ex,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,1,[qw,null],
null),null),xx,$CLJS.N,vx,function(){return fx}],null),new $CLJS.h(null,5,[tx,"G",Sw,new $CLJS.h(null,7,[Zw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$w,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.ax,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),bx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),ex,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,
1,[qw,null],null),null),xx,$CLJS.N,vx,function(){return Qba}],null),new $CLJS.h(null,5,[tx,"$",Sw,new $CLJS.h(null,4,[$w,new $CLJS.P(null,2,5,$CLJS.Q,[2,Number],null),$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Zw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,$CLJS.N,vx,function(){return Rba}],null),new $CLJS.h(null,5,[tx,"%",Sw,new $CLJS.h(null,1,[$CLJS.Xx,
new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),sx,$CLJS.bh,xx,$CLJS.N,vx,function(){return function(a,b){a=$CLJS.Xx.h(a);for(var c=0;;)if(c<a)Eu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[tx,"\x26",Sw,new $CLJS.h(null,1,[$CLJS.Xx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,1,[dy,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){a=$CLJS.Xx.h(a);0<a&&((null!=$CLJS.Du?$CLJS.Du.C&32768||$CLJS.Bc===$CLJS.Du.ag||($CLJS.Du.C?
0:$CLJS.$a($CLJS.ru,$CLJS.Du)):$CLJS.$a($CLJS.ru,$CLJS.Du))?$CLJS.E.g(0,$CLJS.Ku(Nu.h($CLJS.q($CLJS.q($CLJS.Du))),$CLJS.Mu))||Eu():Eu());--a;for(var c=0;;)if(c<a)Eu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[tx,"|",Sw,new $CLJS.h(null,1,[$CLJS.Xx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),sx,$CLJS.bh,xx,$CLJS.N,vx,function(){return function(a,b){a=$CLJS.Xx.h(a);for(var c=0;;)if(c<a)Bw.l($CLJS.H(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[tx,"~",Sw,new $CLJS.h(null,
1,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),sx,$CLJS.bh,xx,$CLJS.N,vx,function(){return function(a,b){a=$CLJS.rk.h(a);Bw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[tx,"\n",Sw,$CLJS.N,sx,new $CLJS.ah(null,new $CLJS.h(null,2,[pw,null,qw,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){$CLJS.n(qw.h(a))&&Eu();return b}}],null),new $CLJS.h(null,5,[tx,"T",Sw,new $CLJS.h(null,2,[Ux,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),
zw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,2,[qw,null,dy,null],null),null),xx,$CLJS.N,vx,function(a){return $CLJS.n(qw.h(a))?function(b,c){var d=Ux.h(b);b=zw.h(b);var e=d+$CLJS.Ku(Nu.h($CLJS.q($CLJS.q($CLJS.Du))),$CLJS.Mu);e=0<b?wu(e,b):0;d+=$CLJS.E.g(0,e)?0:b-e;Bw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}:function(b,c){var d=Ux.h(b);b=zw.h(b);var e=$CLJS.Ku(Nu.h($CLJS.q($CLJS.q($CLJS.Du))),$CLJS.Mu);d=e<d?d-e:$CLJS.E.g(b,0)?0:b-
wu(e-d,b);Bw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}}],null),new $CLJS.h(null,5,[tx,"*",Sw,new $CLJS.h(null,1,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,2,[pw,null,qw,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){var c=$CLJS.rk.h(a);return $CLJS.n(qw.h(a))?nw(b,c):mw(b,$CLJS.n(pw.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[tx,"?",Sw,$CLJS.N,sx,new $CLJS.ah(null,new $CLJS.h(null,1,[qw,null],null),null),xx,
$CLJS.N,vx,function(a){return $CLJS.n(qw.h(a))?function(b,c){var d=lw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return Vw(c,d,Tw.h(b))}:function(b,c){var d=lw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=kw(d);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);d=cw(d);Vw(c,d,Tw.h(b));return e}}],null),new $CLJS.h(null,5,[tx,"(",Sw,$CLJS.N,sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,new $CLJS.h(null,3,[$CLJS.Ax,")",Ex,null,$CLJS.Mt,null],null),vx,function(a){var b=$CLJS.n(function(){var c=
qw.h(a);return $CLJS.n(c)?pw.h(a):c}())?bca:$CLJS.n(pw.h(a))?dca:$CLJS.n(qw.h(a))?eca:aca;return function(c,d){a:{var e=$CLJS.A($CLJS.hx.h(c)),f=$CLJS.Du;$CLJS.Du=b.h?b.h($CLJS.Du):b.call(null,$CLJS.Du);try{var k=Vw(e,d,Tw.h(c));break a}finally{$CLJS.Du=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[tx,")",Sw,$CLJS.N,sx,$CLJS.bh,xx,$CLJS.N,vx,function(){return null}],null),new $CLJS.h(null,5,[tx,"[",Sw,new $CLJS.h(null,1,[gx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),sx,new $CLJS.ah(null,
new $CLJS.h(null,2,[pw,null,qw,null],null),null),xx,new $CLJS.h(null,3,[$CLJS.Ax,"]",Ex,!0,$CLJS.Mt,$CLJS.ay],null),vx,function(a){return $CLJS.n(pw.h(a))?Tba:$CLJS.n(qw.h(a))?Uba:Sba}],null),new $CLJS.h(null,5,[tx,";",Sw,new $CLJS.h(null,2,[mx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),nx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,1,[pw,null],null),null),xx,new $CLJS.h(null,1,[$CLJS.Ot,!0],null),vx,function(){return null}],null),new $CLJS.h(null,
5,[tx,"]",Sw,$CLJS.N,sx,$CLJS.bh,xx,$CLJS.N,vx,function(){return null}],null),new $CLJS.h(null,5,[tx,"{",Sw,new $CLJS.h(null,1,[ix,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,new $CLJS.h(null,2,[$CLJS.Ax,"}",Ex,!1],null),vx,function(a){var b=qw.h(a);b=$CLJS.n(b)?pw.h(a):b;return $CLJS.n(b)?Yba:$CLJS.n(pw.h(a))?Wba:$CLJS.n(qw.h(a))?Xba:Vba}],null),new $CLJS.h(null,5,[tx,"}",Sw,$CLJS.N,sx,new $CLJS.ah(null,
new $CLJS.h(null,1,[pw,null],null),null),xx,$CLJS.N,vx,function(){return null}],null),new $CLJS.h(null,5,[tx,"\x3c",Sw,new $CLJS.h(null,4,[yw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Aw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,4,[pw,null,qw,null,ux,null,dy,null],null),null),xx,new $CLJS.h(null,3,[$CLJS.Ax,"\x3e",Ex,!0,$CLJS.Mt,Dx],null),vx,function(){return $ba}],
null),new $CLJS.h(null,5,[tx,"\x3e",Sw,$CLJS.N,sx,new $CLJS.ah(null,new $CLJS.h(null,1,[pw,null],null),null),xx,$CLJS.N,vx,function(){return null}],null),new $CLJS.h(null,5,[tx,"^",Sw,new $CLJS.h(null,3,[Yx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$x,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Zx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,1,[pw,null],null),null),xx,$CLJS.N,vx,function(){return function(a,b){var c=Yx.h(a),d=$x.h(a),e=
Zx.h(a),f=$CLJS.n(pw.h(a))?Qw:Pw;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.E.g(c,d)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n(c)?$CLJS.E.g(c,0)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:($CLJS.n(pw.h(a))?$CLJS.td($CLJS.Oi.h(Tw.h(a))):$CLJS.td($CLJS.Oi.h(b)))?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b}}],null),new $CLJS.h(null,5,[tx,"W",Sw,$CLJS.N,sx,new $CLJS.ah(null,new $CLJS.h(null,4,[pw,null,qw,null,
ux,null,dy,null],null),null),xx,$CLJS.N,vx,function(a){if($CLJS.n(function(){var c=qw.h(a);return $CLJS.n(c)?c:pw.h(a)}())){var b=$CLJS.gf.g($CLJS.n(qw.h(a))?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pt,null,$CLJS.Vx,null],null):$CLJS.Cf,$CLJS.n(pw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[dy,!0],null):$CLJS.Cf);return function(c,d){d=kw(d);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.n($CLJS.Se.j(iy,c,b))?new $CLJS.P(null,2,5,$CLJS.Q,[Pw,d],null):d}}return function(c,d){d=kw(d);c=$CLJS.I(d,0,null);d=
$CLJS.I(d,1,null);return $CLJS.n($CLJS.Pv(c))?new $CLJS.P(null,2,5,$CLJS.Q,[Pw,d],null):d}}],null),new $CLJS.h(null,5,[tx,"_",Sw,$CLJS.N,sx,new $CLJS.ah(null,new $CLJS.h(null,3,[pw,null,qw,null,ux,null],null),null),xx,$CLJS.N,vx,function(){return gca}],null),new $CLJS.h(null,5,[tx,"I",Sw,new $CLJS.h(null,1,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null)],null),sx,new $CLJS.ah(null,new $CLJS.h(null,1,[pw,null],null),null),xx,$CLJS.N,vx,function(){return fca}],null)]),hca=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
nca=new $CLJS.ah(null,new $CLJS.h(null,2,[sw,null,rw,null],null),null),kca=new $CLJS.h(null,2,[":",pw,"@",qw],null),dw=function dw(a){for(;;){if($CLJS.td(a))return!1;var c=dy.h(sx.h(yx.h($CLJS.A(a))));$CLJS.n(c)||(c=$CLJS.Re(dw,$CLJS.A($CLJS.hx.h(Sw.h($CLJS.A(a))))),c=$CLJS.n(c)?c:$CLJS.Re(dw,$CLJS.A($CLJS.Mt.h(Sw.h($CLJS.A(a))))));if($CLJS.n(c))return!0;a=$CLJS.B(a)}},Ox=$CLJS.Ph(bw),Fx=new $CLJS.h(null,6,[$CLJS.Pj,"'",$CLJS.Tj,"#'",$CLJS.nba,"@",$CLJS.oba,"~",Pca,"@",Nca,"~"],null);
(function(){var a=Ox("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=cw(d);return ew(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()})();
var ky=function(){var a=Ox("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=cw(d);return ew(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ly=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),ida=function(){var a=Ox("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=cw(d);return ew(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),my,jda=$CLJS.Xe($CLJS.N),kda=$CLJS.Xe($CLJS.N),lda=$CLJS.Xe($CLJS.N),mda=$CLJS.Xe($CLJS.N),nda=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
my=new $CLJS.di($CLJS.Fh.g("cljs.pprint","simple-dispatch"),Mx,nda,jda,kda,lda,mda);xu(my,$CLJS.Bj,function(a){if($CLJS.Va(Gx(a)))if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var b=Rv,c=Mv;Rv+=1;Mv=0;try{Gv("(",")");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Pv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Du," ");Xv(Wv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Du,"...");break}Kv()}finally{Mv=c,Rv=b}}return null});xu(my,$CLJS.Jj,Hx);xu(my,$CLJS.hk,Ix);
xu(my,$CLJS.Zi,ky);xu(my,null,function(){return $CLJS.ac($CLJS.Du,$CLJS.Mh.l($CLJS.H([null])))});xu(my,$CLJS.ci,Jx);Ov=my;
var ny=function(){var a=Ox("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=cw(d);return ew(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),oy=function(){var a=Ox("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=cw(d);return ew(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),py=$CLJS.N,oda=function(a){return $CLJS.eg.g($CLJS.N,$CLJS.jf($CLJS.Td,$CLJS.H([function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=new $CLJS.P(null,
2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(t))),$CLJS.hd(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(l))),$CLJS.hd(l)],null)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.eg.g($CLJS.N,$CLJS.hf.g(function(b){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,
1,null);var e=$CLJS.ie(c);e=$CLJS.n(e)?e:$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,24,[$CLJS.$i,"null",$CLJS.pj,"null",$CLJS.sk,"null",$CLJS.uk,"null",$CLJS.kj,"null",$CLJS.Lj,"null",$CLJS.Hj,"null",$CLJS.Mj,"null",$CLJS.qi,"null",$CLJS.Wj,"null",$CLJS.Aj,"null",$CLJS.sj,"null",$CLJS.dk,"null",$CLJS.tk,"null",$CLJS.Ri,"null",$CLJS.Mi,"null",$CLJS.ij,"null",$CLJS.uj,"null",$CLJS.aj,"null",$CLJS.Tj,"null",$CLJS.Pj,"null",$CLJS.kk,"null",$CLJS.Ii,"null",$CLJS.gk,"null"],null),null),c);return $CLJS.Va(e)?
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.g("clojure.core",$CLJS.gh(c)),d],null):b},a))}($CLJS.Ig([$CLJS.aj,$CLJS.Mi,Fca,$CLJS.Wj,Kca,Cca,Eca,Lca,Ica,Hca,Jca,Xca,Rca,$CLJS.gk,Qca,Vca,Tca,cda,Aca,$CLJS.sj,Yca,Zca,bda,$CLJS.Qj,Oca,dda,Bca,Dca,Gca,$ca],[ny,function(a){var b=$CLJS.hd(a),c=$CLJS.A($CLJS.Lc($CLJS.Lc(a)));if($CLJS.zd(b)){a=py;py=$CLJS.E.g(1,$CLJS.D(b))?$CLJS.Ee([$CLJS.A(b),"%"]):$CLJS.eg.g($CLJS.N,$CLJS.hf.j(function(d,e){return new $CLJS.P(null,2,5,$CLJS.Q,[d,["%",$CLJS.p.h(e)].join("")],
null)},b,$CLJS.zu(1,$CLJS.D(b)+1)));try{return function(){var d=Ox("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.w(m,0,null)}return f.call(this,l)}function f(k){k=cw(k);return ew(d,k)}e.A=0;e.B=function(k){k=$CLJS.y(k);return f(k)};e.l=f;return e}()}()(c)}finally{py=a}}else return Px(a)},Rx,oy,function(a){if(3<$CLJS.D(a)){if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,
"#");else{var b=Rv,c=Mv;Rv+=1;Mv=0;try{Gv("(",")");aw(Yv,1);$CLJS.Se.g(function(){var l=Ox("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=cw(u);return ew(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.y($CLJS.bf(3,a));;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,
"#");else{a=Rv;var f=Mv;Rv+=1;Mv=0;try{Gv(null,null),$CLJS.Pv($CLJS.A(e)),$CLJS.B(e)&&($CLJS.ac($CLJS.Du," "),Xv(Uv),$CLJS.Pv($CLJS.hd(e))),Kv()}finally{Mv=f,Rv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Du," ");Xv(Wv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Du,"...");break}Kv()}finally{Mv=c,Rv=b}}return null}return Px(a)},ny,Qx,Qx,Rx,ny,Rx,oy,oy,ny,oy,Rx,Rx,ny,Rx,function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=
"string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{d=Rv;var k=Mv;Rv+=1;Mv=0;try{Gv("(",")");(function(){var m=Ox("~w ~1I~@_~w");return function(){function t(v){var x=
null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=cw(v);return ew(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.y(f))&&function(){var m=Ox("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,
x)}function u(v){v=cw(v);return ew(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&fw(!0,'"~a"~:[~;~:@_~]',$CLJS.H([c,$CLJS.n(e)?e:$CLJS.y(f)]));$CLJS.n(e)&&function(){var m=Ox("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=cw(v);return ew(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.y(f));for(a=f;;){wca($CLJS.A(a));var l=$CLJS.B(a);if(l)b=l,Xv(Wv),a=b;else break}Kv()}finally{Mv=k,Rv=d}}return null}return $CLJS.Pv(a)},Rx,function(a){if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{var b=Rv,c=Mv;Rv+=1;Mv=0;try{Gv("(",")");aw(Yv,1);$CLJS.Pv($CLJS.A(a));if($CLJS.B(a)){$CLJS.ac($CLJS.Du," ");Xv(Wv);for(var d=0,e=$CLJS.B(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{a=Rv;var f=Mv;Rv+=1;Mv=0;try{Gv(null,null),$CLJS.Pv($CLJS.A(e)),
$CLJS.B(e)&&($CLJS.ac($CLJS.Du," "),Xv(Uv),$CLJS.Pv($CLJS.hd(e))),Kv()}finally{Mv=f,Rv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Du," ");Xv(Wv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Du,"...");break}}Kv()}finally{Mv=c,Rv=b}}return null},Rx,Qx,Qx,ny,ny,Rx,Rx,ny]))),qy,pda=$CLJS.Xe($CLJS.N),qda=$CLJS.Xe($CLJS.N),rda=$CLJS.Xe($CLJS.N),sda=$CLJS.Xe($CLJS.N),tda=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
qy=new $CLJS.di($CLJS.Fh.g("cljs.pprint","code-dispatch"),Mx,tda,pda,qda,rda,sda);xu(qy,$CLJS.Bj,function(a){if($CLJS.Va(Gx(a))){var b=oda.call(null,$CLJS.A(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):Px(a)}return null});xu(qy,$CLJS.Gi,function(a){var b=a.h?a.h(py):a.call(null,py);return $CLJS.n(b)?Bw.l($CLJS.H([b])):$CLJS.n(hy)?Bw.l($CLJS.H([$CLJS.gh(a)])):Cu.call(null,a)});xu(qy,$CLJS.Jj,Hx);xu(qy,$CLJS.hk,Ix);xu(qy,$CLJS.Zi,ky);xu(qy,Kx,ida);
xu(qy,Lx,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.ab(a).name;var e=$CLJS.oh(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?ly.h?ly.h(e):ly.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.ya(a)),": "].join("");if($CLJS.n(Sv()))$CLJS.ac($CLJS.Du,"#");else{c=Rv;d=Mv;Rv+=1;Mv=0;try{Gv(b,"\x3e");aw(Yv,-(b.length-2));Xv(Wv);var f=null!=a?a.I&1||$CLJS.Bc===a.jj?!0:a.I?!1:$CLJS.$a(tu,a):$CLJS.$a(tu,a);var k=f?!uu(a):f;$CLJS.Pv(k?Sca:$CLJS.q(a));Kv()}finally{Mv=d,Rv=c}}return null});xu(qy,null,Cu);
xu(qy,$CLJS.ci,Jx);Ov=my;