var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var jV,kV,lV,nV,oV,pV,qV,rV,sV,tV;jV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);kV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);lV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.mV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);nV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);oV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);pV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
qV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);rV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);sV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);tV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(jV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"Valid template tag :widget-type"],null),$CLJS.kA],null),$CLJS.Xg($CLJS.eV)));$CLJS.X(nV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mk,$CLJS.NM,$CLJS.CN,$CLJS.JF,$CLJS.Zt,$CLJS.BB,$CLJS.IP],null));
$CLJS.X(qV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uE,$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.zE,$CLJS.Ms],null)],null)],null));
$CLJS.X(rV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Dl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null)],null));
$CLJS.X(oV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.JF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ss,!0],
null),$CLJS.hk],null)],null)],null));
$CLJS.X(pV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.NM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zM,$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$P,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.jL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.cL],null)],null)],null));
$CLJS.X(tV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.CN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jR,$CLJS.bL],null)],null)],null));$CLJS.X(sV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mk],null),$CLJS.TU));
$CLJS.X(kV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null)],null)],null)],null));
$CLJS.X(lV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.lj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null)],null)],null)],null));
$CLJS.X($CLJS.mV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.zE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));