var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var YL,ZL,$L,Cha,Dha,dM,Eha,gM,hM,iM,jM,Fha,kM,lM,nM,oM,aM,pM,Gha,qM,Hha,rM,sM,tM,Iha,uM,vM,Jha,wM,Kha,xM,yM,Lha,AM,Mha,Nha,Oha,CM,DM,EM,FM,GM,HM,IM,JM,Pha,KM,LM,MM,OM,PM,Qha,QM,RM,SM,TM,Rha,UM,VM,WM,XM,Sha,YM,ZM,$M,aN,Tha,bN,Uha,cN,dN,eN,Vha,fN,gN,hN,iN,kN,lN,Wha,mN,nN,Xha,oN,pN,qN,rN,sN,Yha,tN,Zha,uN,vN,xN,$ha,yN,zN,AN,aia,DN,EN,bia,cia,FN,HN,IN,JN,dia,MN,NN,eia,fia,gia,ON,PN,QN,RN,SN,TN,hia,UN,iia,VN,WN,YN,$N,aO,bO,cO,jia,kia,eO,lia,mia,fO,gO,hO,iO,jO,nia,kO,oia,pia,lO,mO,nO,oO,pO,
qia,ria,sia,qO,tia,rO,uia,tO,uO,via,wO,xO,yO,zO,wia,CO,xia,yia,DO,EO,FO,zia,GO,HO,IO,JO,Aia,LO,MO,NO,OO,PO,Bia,QO,RO,Cia,SO,TO,UO,VO,WO,XO,ZO,Dia,$O,Eia,aP,bP,cP,Fia,Gia,Hia,eP,fP,gP,Iia,hP,iP,jP,kP,Jia,Kia,mP,Lia,Mia,nP,oP,pP,qP,rP,Nia,sP,tP,uP,vP,Oia,wP,xP,yP,zP,BP,Pia,CP,DP,Qia,EP,FP,GP,Ria,HP,Sia,JP,Tia,KP,LP,MP,NP,OP,PP,QP,Uia,Via,Wia,RP,Xia,SP,TP,Yia,Zia,UP,VP,WP,$ia,XP,aja,YP,bja,cja,ZP,dja,eja,fja,bQ,cQ,gja,dQ,eQ,gQ,hja,hQ,ija,iQ,kQ,lQ,jja,kja,mQ,nQ,oQ,pQ,lja,rQ,sQ,mja,tQ,uQ,vQ,wQ,xQ,yQ,zQ,
AQ,nja,BQ,CQ,oja,DQ,pja,EQ,FQ,GQ,qja,HQ,IQ,rja,sja,JQ,KQ,LQ,tja,uja,MQ,vja,wja,xja,OQ,yja,QQ,zja,Aja,RQ,SQ,Bja,Cja,Dja,TQ,UQ,VQ,WQ,XQ,YQ,ZQ,Eja,$Q,aR,Fja,bR,Gja,Hja,Ija,cR,dR,eR,Jja,fR,gR,Kja,hR,Lja,iR,Mja,Nja,kR,lR,mR,nR,oR,Oja,pR,qR,Pja,rR,sR,tR,uR,vR,wR,xR,yR,Qja,zR,AR,CR,DR,ER,FR,GR,HR,IR,JR,KR,LR,Rja,MR,NR,OR,PR,QR,Sja,RR,SR,TR,UR,Tja,WR,Uja,Vja,ZR,Wja,$R,Xja,aS,Yja,Zja,bS,$ja,cS,eS,fS,aka,bka,jS,kS,cka,dka,lS,mS,nS,oS,pS,eka,qS,rS,tS,uS,fka;
$CLJS.XL=function(a){var b=$CLJS.yo.g(a,null),c=$CLJS.tF(b,$CLJS.Ts,function(d){return $CLJS.xn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.ql,b,$CLJS.Cj,k,$CLJS.Zs,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
YL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};ZL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.An($CLJS.yo.g($CLJS.ql.h(a),null));if($CLJS.n(b)){var e=YL($CLJS.zt.h(b),c);$CLJS.n(e)?(e=$CLJS.Iq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:YL($CLJS.xt.h(b),c)}return null};$L=function(a,b){return $CLJS.vd(a)||$CLJS.Jl(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Cha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.ql),d=$CLJS.J.g(a,$CLJS.lj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Zs,$CLJS.mea),f=$CLJS.J.j(b,$CLJS.hea,!0),k=$CLJS.J.g(b,$CLJS.iC),l=$CLJS.J.j(b,$CLJS.gC,$CLJS.eC),m=ZL(a,$CLJS.Uq.h(c),k,b);if($CLJS.n(m))return m;m=ZL(a,$CLJS.kt.h(c),k,b);if($CLJS.n(m))return m;m=ZL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=ZL(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=ZL(a,$CLJS.Uq.h(c),
l,b);if($CLJS.n(m))return m;m=ZL(a,$CLJS.kt.h(c),l,b);if($CLJS.n(m))return m;d=ZL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=ZL(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?ZL(a,e.h?e.h($CLJS.cC):e.call(null,$CLJS.cC),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?ZL(a,e.h?e.h($CLJS.cC):e.call(null,$CLJS.cC),l,b):f};
Dha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.nl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.ql),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.iC);l=$CLJS.J.j(l,$CLJS.gC,$CLJS.eC);k=$CLJS.Uq.h(k);f=YL($CLJS.fC.h(k),f);f=$CLJS.n(f)?f:YL($CLJS.fC.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Cha(b,c)],null)};
$CLJS.bM=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Cj);a=$CLJS.J.g(b,$CLJS.Zs);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.iea,$CLJS.Pi),f=$CLJS.J.j(d,$CLJS.kea,Dha);return $CLJS.n(a)?$CLJS.fb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Pi,m);l=e.h?e.h(l):e.call(null,l);return aM(k,c,t,l)},null,a):null};
dM=function(a,b,c){var d=$CLJS.GA($CLJS.q($CLJS.hE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.hE,$CLJS.cM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.eM=function(a){return dM($CLJS.xk,a,function(){return $CLJS.uF.h(a)})};Eha=function(){var a=fM;return dM($CLJS.Ts,a,function(){var b=$CLJS.uF.h(a),c=$CLJS.XL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
gM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};hM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,gM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,gM(c)],null);default:return gM(a)}}else return gM(a)};
iM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
jM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(iM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,hM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,hM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.nt(2,2,b))}())],null)};Fha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
kM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.Ni,Fha,$CLJS.xt,["valid instance of one of these MBQL clauses: ",$CLJS.It(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
lM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WL],null)],null)};$CLJS.mM={};nM={};oM={};$CLJS.cM=function cM(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=cM.j?cM.j(k,f,c):cM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
aM=function aM(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=$L(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.dC.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=$L(f,c),d=aM.v?aM.v(b,l,k,d):aM.call(null,b,l,k,d),$CLJS.Ol(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Jl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jea],null),aM.v?aM.v(f,b,c,d):aM.call(null,f,b,c,d)):$CLJS.n($CLJS.dC.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.dC,!0],null))};pM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Gha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
qM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Hha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);rM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);sM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);tM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Iha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);uM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);vM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Jha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);wM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Kha=new $CLJS.M(null,"from","from",1815293044);xM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);yM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Lha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.zM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);AM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Mha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Nha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.BM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Oha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);CM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
DM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);EM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);FM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);GM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);HM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
IM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);JM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Pha=new $CLJS.M(null,"lon-max","lon-max",1590224717);KM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);LM=new $CLJS.r(null,"stddev","stddev",775056588,null);MM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.NM=new $CLJS.M(null,"snippet","snippet",953581994);OM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);PM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Qha=new $CLJS.M(null,"lat-field","lat-field",-830652957);QM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);RM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
SM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);TM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Rha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);UM=new $CLJS.M("location","country","location/country",1666636202);VM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
WM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);XM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Sha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);YM=new $CLJS.M(null,"unary","unary",-989314568);ZM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
$M=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);aN=new $CLJS.r(null,"ceil","ceil",-184398425,null);Tha=new $CLJS.M(null,"lon-min","lon-min",-787291357);bN=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Uha=new $CLJS.M(null,"match","match",1220059550);cN=new $CLJS.r(null,"count-where","count-where",2025939247,null);dN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
eN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Vha=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);fN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);gN=new $CLJS.r(null,"sum","sum",1777518341,null);hN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);iN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.jN=new $CLJS.M("date","range","date/range",1647265776);kN=new $CLJS.r(null,"between","between",-1523336493,null);lN=new $CLJS.M(null,"clause-form","clause-form",1820463737);Wha=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);mN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);nN=new $CLJS.r(null,"field","field",338095027,null);Xha=new $CLJS.M(null,"segment-id","segment-id",1810133590);
oN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);pN=new $CLJS.r(null,"not-null","not-null",313812992,null);qN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);rN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);sN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Yha=new $CLJS.M(null,"template-tag","template-tag",310841038);
tN=new $CLJS.M(null,"invalid","invalid",412869516);Zha=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);uN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);vN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.wN=new $CLJS.M(null,"context","context",-830191113);xN=new $CLJS.r(null,"get-year","get-year",704520253,null);
$ha=new $CLJS.M(null,"format-rows?","format-rows?",992129486);yN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);zN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);AN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.BN=new $CLJS.M(null,"parameters","parameters",-1229919748);aia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.CN=new $CLJS.M(null,"card","card",-1430355152);DN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);EN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);bia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);cia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);FN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.GN=new $CLJS.M("date","month-year","date/month-year",1948031290);
HN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);IN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);JN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.KN=new $CLJS.M(null,"joins","joins",1033962699);dia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.LN=new $CLJS.M(null,"source-field","source-field",933829534);MN=new $CLJS.r(null,"Field","Field",430385967,null);
NN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);eia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);fia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);gia=new $CLJS.M(null,"items","items",1031954938);ON=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);PN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
QN=new $CLJS.M(null,"more","more",-2058821800);RN=new $CLJS.M(null,"first-clause","first-clause",-20953491);SN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);TN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);hia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);UN=new $CLJS.r(null,"contains","contains",-1977535957,null);
iia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);VN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);WN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.XN=new $CLJS.M(null,"widget-type","widget-type",1836256899);YN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.ZN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
$N=new $CLJS.r(null,"is-null","is-null",-356519403,null);aO=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);bO=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);cO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);jia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.dO=new $CLJS.M(null,"required","required",1807647006);kia=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
eO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);lia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);mia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);fO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);gO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);hO=new $CLJS.M("string","contains","string/contains",1602423827);iO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
jO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);nia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);kO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);oia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);pia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
lO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);mO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);nO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);oO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);pO=new $CLJS.r(null,"share","share",1051097594,null);
qia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);ria=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);sia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);qO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);tia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
rO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);uia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.sO=new $CLJS.M(null,"collection","collection",-683361892);tO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);uO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.vO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);via=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);wO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);xO=new $CLJS.r(null,"metric","metric",2049329604,null);yO=new $CLJS.r(null,"concat","concat",-467652465,null);zO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.AO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
wia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.BO=new $CLJS.M("date","relative","date/relative",25987732);CO=new $CLJS.M("location","city","location/city",-1746973325);xia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);yia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);DO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);EO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
FO=new $CLJS.M("number","between","number/between",97700581);zia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);GO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);HO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);IO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);JO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.KO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Aia=new $CLJS.M(null,"metric-id","metric-id",-686486942);LO=new $CLJS.r(null,"*","*",345799209,null);MO=new $CLJS.r(null,"+","+",-740910886,null);NO=new $CLJS.r(null,"-","-",-471816912,null);OO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);PO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Bia=new $CLJS.M(null,"question","question",-1411720117);QO=new $CLJS.r(null,"asc","asc",1997386096,null);
RO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Cia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);SO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);TO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);UO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);VO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);WO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
XO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.YO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);ZO=new $CLJS.M("string","ends-with","string/ends-with",302681156);Dia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);$O=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Eia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
aP=new $CLJS.r(null,"and","and",668631710,null);bP=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);cP=new $CLJS.r(null,"round","round",-645002441,null);Fia=new $CLJS.M(null,"to","to",192099007);$CLJS.dP=new $CLJS.M("date","single","date/single",1554682003);Gia=new $CLJS.M(null,"action-id","action-id",-1727958578);Hia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);eP=new $CLJS.r(null,"exp","exp",1378825265,null);
fP=new $CLJS.r(null,"Filter","Filter",-424893332,null);gP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Iia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);hP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);iP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);jP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
kP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.lP=new $CLJS.M(null,"source-table","source-table",-225307692);Jia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Kia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);mP=new $CLJS.r(null,"floor","floor",-772394748,null);Lia=new $CLJS.M(null,"middleware","middleware",1462115504);
Mia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);nP=new $CLJS.M(null,"requires-features","requires-features",-101116256);oP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);pP=new $CLJS.M(null,"clause-name","clause-name",-996419059);qP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);rP=new $CLJS.r(null,"now","now",-9994004,null);
Nia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);sP=new $CLJS.r(null,"not","not",1044554643,null);tP=new $CLJS.r(null,"avg","avg",1837937727,null);uP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);vP=new $CLJS.M(null,"max-results","max-results",-32858165);Oia=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);wP=new $CLJS.r(null,"case","case",-1510733573,null);
xP=new $CLJS.r(null,"distinct","distinct",-148347594,null);yP=new $CLJS.r(null,"get-second","get-second",-425414791,null);zP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.AP=new $CLJS.M(null,"join-alias","join-alias",1454206794);BP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Pia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
CP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);DP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Qia=new $CLJS.M(null,"original","original",-445386197);EP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);FP=new $CLJS.r(null,"abs","abs",1394505050,null);GP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Ria=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
HP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Sia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.IP=new $CLJS.M(null,"date","date",-1463434462);JP=new $CLJS.M(null,"second-clause","second-clause",-461435645);Tia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);KP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
LP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);MP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);NP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);OP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);PP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);QP=new $CLJS.r(null,"or","or",1876275696,null);
Uia=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Via=new $CLJS.M(null,"constraints","constraints",422775616);Wia=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);RP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Xia=new $CLJS.M(null,"csv-download","csv-download",2141432084);SP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
TP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Yia=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Zia=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);UP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);VP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
WP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);$ia=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);XP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);aja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);YP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);bja=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
cja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);ZP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);dja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);eja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);fja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.$P=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.aQ=new $CLJS.M(null,"database","database",1849087575);bQ=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);cQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);gja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);dQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
eQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.fQ=new $CLJS.M(null,"expressions","expressions",255689909);gQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);hja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);hQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
ija=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);iQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);kQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);lQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);jja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);kja=new $CLJS.M(null,"action","action",-811238024);
mQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);nQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);oQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);pQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.qQ=new $CLJS.M(null,"native","native",-613060878);lja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);rQ=new $CLJS.M(null,"page","page",849072397);
sQ=new $CLJS.r(null,"length","length",-2065447907,null);mja=new $CLJS.M(null,"dashboard","dashboard",-631747508);tQ=new $CLJS.r(null,"get-week","get-week",752472178,null);uQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);vQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);wQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);xQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
yQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);zQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);AQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);nja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);BQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);CQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
oja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);DQ=new $CLJS.M(null,"internal","internal",-854870097);pja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);EQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);FQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
GQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);qja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);HQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);IQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);rja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
sja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);JQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);KQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);LQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
tja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);uja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);MQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.NQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);vja=new $CLJS.M(null,"public-question","public-question",629369976);wja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
xja=new $CLJS.M(null,"binary","binary",-1802232288);OQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);yja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.PQ=new $CLJS.M(null,"source-query","source-query",198004422);QQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);zja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
Aja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);RQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);SQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Bja=new $CLJS.M(null,"executed-by","executed-by",-739811161);Cja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Dja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);TQ=new $CLJS.M(null,"amount","amount",364489504);UQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);VQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);WQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);XQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
YQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);ZQ=new $CLJS.r(null,"trim","trim",-1880116002,null);Eja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);$Q=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);aR=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Fja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
bR=new $CLJS.M("string","\x3d","string/\x3d",983744235);Gja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Hja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Ija=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);cR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);dR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
eR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Jja=new $CLJS.M(null,"lat-min","lat-min",1630784161);fR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);gR=new $CLJS.r(null,"inside","inside",-681932758,null);Kja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);hR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Lja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);iR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Mja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Nja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.jR=new $CLJS.M(null,"card-id","card-id",-1770060179);kR=new $CLJS.M(null,"variadic","variadic",882626057);
lR=new $CLJS.r(null,"upper","upper",1886775433,null);mR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);nR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);oR=new $CLJS.r(null,"optional","optional",-600484260,null);Oja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);pR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
qR=new $CLJS.M(null,"sugar","sugar",-73788488);Pja=new $CLJS.M(null,"lat-max","lat-max",841568226);rR=new $CLJS.r(null,"power","power",702679448,null);sR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);tR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);uR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
vR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);wR=new $CLJS.r(null,"median","median",-2084869638,null);xR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);yR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Qja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);zR=new $CLJS.M(null,"y","y",-1757859776);AR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.BR=new $CLJS.M(null,"binning","binning",1709835866);CR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);DR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);ER=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);FR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);GR=new $CLJS.r(null,"Reference","Reference",2024574086,null);HR=new $CLJS.M(null,"b","b",1482224470);
IR=new $CLJS.M(null,"a","a",-2123407586);JR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);KR=new $CLJS.r(null,"replace","replace",853943757,null);LR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Rja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);MR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
NR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);OR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);PR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);QR=new $CLJS.r(null,"segment","segment",675610331,null);Sja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);RR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
SR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);TR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);UR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Tja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.VR=new $CLJS.M(null,"order-by","order-by",1527318070);WR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Uja=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Vja=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.YR=new $CLJS.M(null,"condition","condition",1668437652);ZR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Wja=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);$R=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Xja=new $CLJS.M(null,"card-name","card-name",-2035606807);aS=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Yja=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Zja=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);bS=new $CLJS.r(null,"log","log",45015523,null);$ja=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
cS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.dS=new $CLJS.M(null,"database_type","database_type",-54700895);eS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);fS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.gS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.hS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.iS=new $CLJS.M("date","all-options","date/all-options",549325958);
aka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);bka=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);jS=new $CLJS.M("location","state","location/state",-114378652);kS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);cka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);dka=new $CLJS.M(null,"lon-field","lon-field",517872067);
lS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);mS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);nS=new $CLJS.M(null,"numeric","numeric",-1495594714);oS=new $CLJS.r(null,"variable","variable",1359185035,null);pS=new $CLJS.r(null,"lower","lower",-1534114948,null);eka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
qS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);rS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.sS=new $CLJS.M(null,"limit","limit",-1355822363);tS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);uS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);fka=new $CLJS.M(null,"pulse","pulse",-244494476);var vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zE],null),wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null),xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vE],null),yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rE],null),zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null),AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kL],null),gka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bL],null),hka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.NI],null),CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RI],null),DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.II],null),ES=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.oi,null,$CLJS.eu,null,$CLJS.nj,null,$CLJS.ci,null,$CLJS.Fi,null,$CLJS.wj,null,$CLJS.lk,null,$CLJS.Fj,null,$CLJS.ji,null,$CLJS.Ej,null,$CLJS.pi,null,$CLJS.zi,null],null),null),FS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.qk,null,$CLJS.cH,null,$CLJS.ci,null,$CLJS.vi,null,$CLJS.fH,null,$CLJS.hj,null,$CLJS.Ti,null],null),null),
GS=$CLJS.fu.g(ES,FS),ika=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"date bucketing unit"],null)],null),ES),jka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"time bucketing unit"],null)],null),FS),HS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"datetime bucketing unit"],null)],null),GS),IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rJ],null),kka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,
1,[$CLJS.xt,"temporal extract unit"],null),$CLJS.gH,$CLJS.zi,$CLJS.lk,$CLJS.cJ,$CLJS.xJ,$CLJS.yJ,$CLJS.Fj,$CLJS.Fi,$CLJS.vi,$CLJS.hj,$CLJS.dH],null),lka=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"datetime-diff unit"],null),$CLJS.cH,$CLJS.Ti,$CLJS.qk,$CLJS.eu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null),JS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"temporal-extract week extraction mode"],null),$CLJS.XI,$CLJS.fJ,$CLJS.kJ],null),KS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Ti,$CLJS.qk,$CLJS.eu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null),RS,nka,ZS,$S,aT,bT,cT,dT,eT,QU,pka,RU,qka,rka,SU,ska,tka,uka;$CLJS.X($M,jM($CLJS.gJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.Zv],null),$CLJS.Js],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,KS],null)])));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null);
$CLJS.X(EP,jM($CLJS.eJ,$CLJS.H(["n",$CLJS.Js,"unit",KS])));var mka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EP],null);
$CLJS.X(uN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"valid :absolute-datetime clause",$CLJS.Ni,function(a){if($CLJS.Va(iM($CLJS.aJ,a)))a=tN;else{a=$CLJS.hd(a);var b=$CLJS.eM(BS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.IP:$CLJS.hJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[tN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IP,jM($CLJS.aJ,
$CLJS.H(["date",BS,"unit",ika]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,jM($CLJS.aJ,$CLJS.H(["datetime",CS,"unit",HS]))],null)],null));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uN],null);$CLJS.X(JN,jM($CLJS.Ly,$CLJS.H(["time",DS,"unit",jka])));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JN],null),OS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"date or datetime literal"],null),MS,CS,BS],null);
$CLJS.X(uM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"time literal"],null),NS,DS],null));$CLJS.X(lS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"temporal literal"],null),OS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null)],null));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lS],null);
$CLJS.X(Fja,kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof MS?new $CLJS.Gc(function(){return MS},$CLJS.od(eia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,DQ,$CLJS.mj,$CLJS.yk,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lQ,"metabase/mbql/schema.cljc",69,$CLJS.aJ,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:lQ}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof LS?new $CLJS.Gc(function(){return LS},$CLJS.od(aka,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,
$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",29,$CLJS.gJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gJ,$CLJS.rk,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.Zv],null),$CLJS.Js],null)),$CLJS.ri,$CLJS.U($CLJS.Pj,$CLJS.U(oR,$O))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:CP}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof NS?new $CLJS.Gc(function(){return NS},$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,DQ,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cS,"metabase/mbql/schema.cljc",27,$CLJS.Ly,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ly,$CLJS.Ly,$CLJS.U($CLJS.Pj,Eja),$CLJS.ri,$CLJS.U($CLJS.Pj,wia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:cS}(),NS],null)])));
$CLJS.X(aS,jM($CLJS.Cj,$CLJS.H(["value",$CLJS.Os,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dS,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ws,xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,HS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,vS],null)],null)],null)],null)])));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aS],null);$CLJS.X(JO,jM($CLJS.uB,$CLJS.H(["expression-name",vS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.hk],null)])));
RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JO],null);
nka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.KE);c=$CLJS.J.g(c,$CLJS.sE);return $CLJS.E.g(b,$CLJS.sE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.KE);c=$CLJS.J.g(c,$CLJS.JE);return $CLJS.E.g(b,$CLJS.JE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"binning strategy"],null),$CLJS.sE,$CLJS.JE,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),yS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.JE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.Bl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Wl)],null)],null)],null)],null)));$CLJS.SS=function SS(a){switch(arguments.length){case 1:return SS.h(arguments[0]);case 2:return SS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.SS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.xB);b=$CLJS.J.g(b,$CLJS.vG);return $CLJS.SS.g(a,b)};$CLJS.SS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.xC(a,$CLJS.YC)?ES:$CLJS.xC(a,$CLJS.XD)?FS:$CLJS.xC(a,$CLJS.xD)?GS:null:null;return $CLJS.n(a)?$CLJS.Hd(a,b):!0};$CLJS.SS.A=2;
$CLJS.X(pR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xB,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,HS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,nka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.SS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.KE)],null)],null));
$CLJS.X(RR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.xB);return"string"===typeof c?b:!0}],null)],null)}(jM($CLJS.pG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,AS,vS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pR],null)],null)]))));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RR],null);
$CLJS.X(RP,kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},$CLJS.od(VN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FN,"metabase/mbql/schema.cljc",60,$CLJS.uB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.uB,$CLJS.hS,$CLJS.U($CLJS.Pj,bN),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,$CLJS.hk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:FN}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof TS?new $CLJS.Gc(function(){return TS},
$CLJS.od(mO,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.wr,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,$CLJS.ti,$CLJS.jk,$CLJS.ok],["0.39.0",$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nN,"metabase/mbql/schema.cljc",51,$CLJS.pG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:nN}(),TS],null)])));
$CLJS.US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RP],null);$CLJS.X(pM,jM($CLJS.eG,$CLJS.H(["aggregation-clause-index",$CLJS.Js,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.hk],null)])));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);
$CLJS.X(WM,kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof VS?new $CLJS.Gc(function(){return VS},$CLJS.od(Cja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AR,"metabase/mbql/schema.cljc",23,$CLJS.eG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.eG,cka,$CLJS.U($CLJS.Pj,$CLJS.Js),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,$CLJS.hk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:AR}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},
$CLJS.od(VN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FN,"metabase/mbql/schema.cljc",60,$CLJS.uB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.uB,$CLJS.hS,$CLJS.U($CLJS.Pj,bN),
$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,$CLJS.hk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:FN}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof TS?new $CLJS.Gc(function(){return TS},$CLJS.od(mO,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.wr,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,
$CLJS.ti,$CLJS.jk,$CLJS.ok],["0.39.0",$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nN,"metabase/mbql/schema.cljc",51,$CLJS.pG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:nN}(),TS],null)])));
var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null),XS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Kt,null,$CLJS.IG,null,$CLJS.IF,null,$CLJS.OG,null,$CLJS.PF,null,$CLJS.sG,null,$CLJS.ZF,null,$CLJS.Qt,null,$CLJS.GG,null,$CLJS.rG,null,$CLJS.PG,null],null),null),YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);
$CLJS.X(wM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ni,function(a){return"string"===typeof a?$CLJS.Yj:$CLJS.n(iM(XS,a))?PM:$CLJS.n(iM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,$CLJS.Yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[PM,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.US],null)],null));ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null);
$S=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.LF,null,$CLJS.wJ,null,$CLJS.ds,null,$CLJS.sJ,null,$CLJS.pJ,null,$CLJS.cG,null,$CLJS.qt,null,$CLJS.HF,null,$CLJS.tJ,null,$CLJS.mJ,null,$CLJS.sG,null,$CLJS.LG,null,$CLJS.qJ,null,$CLJS.uG,null,$CLJS.NF,null,$CLJS.ZI,null,$CLJS.cs,null,$CLJS.QF,null,$CLJS.ZF,null,$CLJS.TI,null,$CLJS.Vx,null,$CLJS.$I,null,$CLJS.lJ,null,$CLJS.dJ,null,$CLJS.NG,null,$CLJS.nG,null],null),null);
aT=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.$r,null,$CLJS.Ur,null,$CLJS.xs,null,$CLJS.zs,null,$CLJS.Wr,null,$CLJS.HG,null,$CLJS.Es,null,$CLJS.as,null,$CLJS.Yr,null],null),null);bT=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.Zn,null,$CLJS.FG,null,$CLJS.yG,null,$CLJS.qG,null,$CLJS.KG,null,$CLJS.MG,null,$CLJS.VF,null,$CLJS.aG,null,$CLJS.BM,null,$CLJS.WF,null,$CLJS.DG,null,$CLJS.wG,null,$CLJS.ik,null,$CLJS.Xx,null,$CLJS.jG,null,$CLJS.kG,null,$CLJS.CG,null],null),null);
cT=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.iJ,null,$CLJS.VI,null,$CLJS.uJ,null,$CLJS.cs,null,$CLJS.oJ,null],null),null);dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hR],null);eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HN],null);$CLJS.fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);$CLJS.gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nR],null);
$CLJS.X(uR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"numeric expression argument",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.Zt:$CLJS.n(iM($S,a))?kP:$CLJS.n(iM(bT,a))?$CLJS.eG:$CLJS.n(iM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.pG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zt,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[kP,dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,$CLJS.gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pG,
$CLJS.US],null)],null));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uR],null);
$CLJS.X(EQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"datetime expression argument",$CLJS.Ni,function(a){return $CLJS.n(iM(bT,a))?$CLJS.eG:$CLJS.n(iM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.n(iM(cT,a))?ON:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,$CLJS.gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[ON,$CLJS.fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,OS,$CLJS.US],null)],null)],null));
var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);
$CLJS.X(gQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"expression argument",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.Zt:$CLJS.Cd(a)?$CLJS.Ns:$CLJS.n(iM(aT,a))?wQ:$CLJS.n(iM($S,a))?kP:$CLJS.n(iM(cT,a))?ON:"string"===typeof a?$CLJS.Yj:$CLJS.n(iM(XS,a))?PM:$CLJS.n(iM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zt,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ns,$CLJS.Ns],null),new $CLJS.P(null,2,5,$CLJS.Q,[wQ,eT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[kP,dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[ON,$CLJS.fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,$CLJS.Yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[PM,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.US],null)],null));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null);$CLJS.X(eQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"numeric expression arg or interval"],null),mka,hT],null));
var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eQ],null);$CLJS.X(HM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"int greater than zero or numeric expression",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.Zt:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zt,yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,dT],null)],null));var oka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HM],null);
$CLJS.X(PN,jM($CLJS.sG,$CLJS.H(["a",jT,"b",jT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,jT],null)])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PN],null);$CLJS.X(tM,jM($CLJS.IF,$CLJS.H(["s",ZS,"start",oka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,hT],null)])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);$CLJS.X(xQ,jM($CLJS.Vx,$CLJS.H(["s",ZS])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null);$CLJS.X(VM,jM($CLJS.GG,$CLJS.H(["s",ZS])));
var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null);$CLJS.X(ZM,jM($CLJS.PG,$CLJS.H(["s",ZS])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null);$CLJS.X(rN,jM($CLJS.IG,$CLJS.H(["s",ZS])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rN],null);$CLJS.X(cO,jM($CLJS.Kt,$CLJS.H(["s",ZS])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cO],null);$CLJS.X(MM,jM($CLJS.Qt,$CLJS.H(["s",ZS])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MM],null);
$CLJS.X(tR,jM($CLJS.OG,$CLJS.H(["s",ZS,"match",$CLJS.Yj,"replacement",$CLJS.Yj])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tR],null);$CLJS.X(qS,jM($CLJS.PF,$CLJS.H(["a",ZS,"b",ZS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,ZS],null)])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qS],null);$CLJS.X(SO,jM($CLJS.rG,$CLJS.H(["s",ZS,"pattern",$CLJS.Yj])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SO],null);
$CLJS.X(PR,jM($CLJS.cs,$CLJS.H(["x",kT,"y",kT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,kT],null)])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);$CLJS.X(NR,jM($CLJS.qt,$CLJS.H(["x",hT,"y",kT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,kT],null)])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(MR,jM($CLJS.LG,$CLJS.H(["x",hT,"y",hT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,hT],null)])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MR],null);
$CLJS.X(OR,jM($CLJS.ds,$CLJS.H(["x",hT,"y",hT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,hT],null)])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OR],null);$CLJS.X(dQ,jM($CLJS.nG,$CLJS.H(["x",hT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dQ],null);$CLJS.X(XQ,jM($CLJS.LF,$CLJS.H(["x",hT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XQ],null);$CLJS.X(tS,jM($CLJS.uG,$CLJS.H(["x",hT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tS],null);$CLJS.X(iP,jM($CLJS.QF,$CLJS.H(["x",hT])));
var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iP],null);$CLJS.X(oP,jM($CLJS.NF,$CLJS.H(["x",hT,"y",hT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oP],null);$CLJS.X(LP,jM($CLJS.cG,$CLJS.H(["x",hT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);$CLJS.X(IM,jM($CLJS.HF,$CLJS.H(["x",hT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IM],null);$CLJS.X(kQ,jM($CLJS.NG,$CLJS.H(["x",hT])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kQ],null);
$CLJS.X(hN,jM($CLJS.dJ,$CLJS.H(["datetime-x",iT,"datetime-y",iT,"unit",lka])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hN],null);$CLJS.X(zN,jM($CLJS.sJ,$CLJS.H(["datetime",iT,"unit",kka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,JS],null)])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zN],null);$CLJS.X(qP,jM($CLJS.$I,$CLJS.H(["date",iT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qP],null);$CLJS.X(SP,jM($CLJS.wJ,$CLJS.H(["date",iT])));
var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SP],null);$CLJS.X(vM,jM($CLJS.TI,$CLJS.H(["date",iT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null);$CLJS.X(rS,jM($CLJS.mJ,$CLJS.H(["date",iT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,JS],null)])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rS],null);$CLJS.X(DO,jM($CLJS.lJ,$CLJS.H(["date",iT])));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DO],null);$CLJS.X(MP,jM($CLJS.qJ,$CLJS.H(["date",iT])));
var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null);$CLJS.X(dN,jM($CLJS.tJ,$CLJS.H(["datetime",iT])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null);$CLJS.X(IO,jM($CLJS.pJ,$CLJS.H(["datetime",iT])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IO],null);$CLJS.X(XM,jM($CLJS.ZI,$CLJS.H(["datetime",iT])));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null);$CLJS.X(nO,jM($CLJS.VI,$CLJS.H(["datetime",iT,"to",IS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,IS],null)])));
var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nO],null),UT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"datetime arithmetic unit"],null),$CLJS.fH,$CLJS.cH,$CLJS.Ti,$CLJS.qk,$CLJS.eu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null);$CLJS.X(sM,jM($CLJS.oJ,$CLJS.H(["datetime",iT,"amount",hT,"unit",UT])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null);
$CLJS.X(QM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return jM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.iJ));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null);$CLJS.X(rM,jM($CLJS.uJ,$CLJS.H(["datetime",iT,"amount",hT,"unit",UT])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);
$CLJS.X(JQ,kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(YP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MO,"metabase/mbql/schema.cljc",51,$CLJS.cs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cs,$CLJS.HE,$CLJS.U($CLJS.Pj,bQ),zR,$CLJS.U($CLJS.Pj,bQ),QN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,bQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:MO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},
$CLJS.od(Nia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[gO,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",67,$CLJS.oJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oJ,$CLJS.hJ,$CLJS.U($CLJS.Pj,mS),TQ,$CLJS.U($CLJS.Pj,
YQ),$CLJS.ri,$CLJS.U($CLJS.Pj,fR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:TP}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(Gha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,
$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[gO,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kS,"metabase/mbql/schema.cljc",72,$CLJS.uJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uJ,$CLJS.hJ,$CLJS.U($CLJS.Pj,mS),TQ,$CLJS.U($CLJS.Pj,YQ),$CLJS.ri,$CLJS.U($CLJS.Pj,fR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(XT)?XT.H:null])):null));
return $CLJS.n(a)?a:kS}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(Lja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.VI,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NN,"metabase/mbql/schema.cljc",71,$CLJS.VI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.VI,$CLJS.hJ,$CLJS.U($CLJS.Pj,mS),Fia,$CLJS.U($CLJS.Pj,CR),Kha,$CLJS.U($CLJS.Pj,$CLJS.U(oR,CR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:NN}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&
"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.iJ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",
45,$CLJS.iJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.iJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:rP}(),WT],null)])));$CLJS.YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OP],null);$CLJS.X(xM,jM($CLJS.Es,$CLJS.H(["first-clause",$CLJS.YT,"second-clause",$CLJS.YT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,$CLJS.YT],null)])));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xM],null);
$CLJS.X(jO,jM($CLJS.xs,$CLJS.H(["first-clause",$CLJS.YT,"second-clause",$CLJS.YT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,$CLJS.YT],null)])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null);$CLJS.X(SM,jM($CLJS.zs,$CLJS.H(["clause",$CLJS.YT])));
var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null),bU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,3,[$CLJS.xt,":field or :expression reference or :relative-datetime",$CLJS.zt,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Ni,function(a){return $CLJS.n(iM($CLJS.gJ,a))?$CLJS.gJ:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.US],null)],null);
$CLJS.X(GQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.xt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.xs,$CLJS.Ns,$CLJS.Bl,$CLJS.Yj,PS,bU,jT,QS],null)],null));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);
$CLJS.X(XO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"order comparable",$CLJS.Ni,function(a){return $CLJS.n(iM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.xs,$CLJS.Bl,$CLJS.Yj,PS,jT,bU],null)],null)],null));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XO],null);
$CLJS.X(FR,jM($CLJS.as,$CLJS.H(["field",cU,"value-or-field",cU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,cU],null)])));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FR],null);$CLJS.X(JR,jM($CLJS.HG,$CLJS.H(["field",cU,"value-or-field",cU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,cU],null)])));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JR],null);$CLJS.X(ER,jM($CLJS.Yr,$CLJS.H(["field",dU,"value-or-field",dU])));
var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ER],null);$CLJS.X(DR,jM($CLJS.Ur,$CLJS.H(["field",dU,"value-or-field",dU])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DR],null);$CLJS.X(sN,jM($CLJS.$r,$CLJS.H(["field",dU,"value-or-field",dU])));var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);$CLJS.X(yN,jM($CLJS.Wr,$CLJS.H(["field",dU,"value-or-field",dU])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yN],null);$CLJS.X(oQ,jM($CLJS.SF,$CLJS.H(["field",dU,"min",dU,"max",dU])));
var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null);$CLJS.X(QQ,jM($CLJS.YF,$CLJS.H(["lat-field",dU,"lon-field",dU,"lat-max",dU,"lon-min",dU,"lat-min",dU,"lon-max",dU])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QQ],null);$CLJS.X(bO,jM($CLJS.RF,$CLJS.H(["field",$CLJS.US])));var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bO],null);$CLJS.X(RO,jM($CLJS.bG,$CLJS.H(["field",$CLJS.US])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RO],null);$CLJS.X(tO,jM($CLJS.TF,$CLJS.H(["field",$CLJS.US])));
var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tO],null);$CLJS.X(AM,jM($CLJS.$F,$CLJS.H(["field",$CLJS.US])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null),qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null);$CLJS.X(GO,jM($CLJS.gG,$CLJS.H(["field",ZS,"string-or-field",ZS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,qU],null)])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GO],null);
$CLJS.X(zP,jM($CLJS.AG,$CLJS.H(["field",ZS,"string-or-field",ZS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,qU],null)])));var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zP],null);$CLJS.X(rO,jM($CLJS.oG,$CLJS.H(["field",ZS,"string-or-field",ZS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,qU],null)])));var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rO],null);$CLJS.X(hQ,jM($CLJS.hG,$CLJS.H(["field",ZS,"string-or-field",ZS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,qU],null)])));
var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);$CLJS.X(JM,jM($CLJS.BG,$CLJS.H(["field",$CLJS.US,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.Js,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mk,$CLJS.Zv,$CLJS.ay,$CLJS.XF],null)],null),"unit",KS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null)],null)])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JM],null);
$CLJS.X(zQ,jM($CLJS.OF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VK],null),vS],null)])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);
$CLJS.X(HN,kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(sR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aP,"metabase/mbql/schema.cljc",15,$CLJS.Es,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,RN,$CLJS.U($CLJS.Pj,fP),JP,$CLJS.U($CLJS.Pj,fP),aO,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,fP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:aP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},
$CLJS.od(NP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",14,$CLJS.xs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,RN,$CLJS.U($CLJS.Pj,fP),JP,$CLJS.U($CLJS.Pj,fP),aO,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,fP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:QP}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(AQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",15,$CLJS.zs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.gS,$CLJS.U($CLJS.Pj,fP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:sP}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==
typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",13,$CLJS.Yr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yr,$CLJS.pG,$CLJS.U($CLJS.Pj,TN),dR,$CLJS.U($CLJS.Pj,TN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:UO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od($R,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",14,$CLJS.$r,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$r,$CLJS.pG,$CLJS.U($CLJS.Pj,TN),dR,$CLJS.U($CLJS.Pj,TN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:TO}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&
"undefined"!==typeof oM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ur,$CLJS.pG,$CLJS.U($CLJS.Pj,
TN),dR,$CLJS.U($CLJS.Pj,TN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:VO}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(SR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],
[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",14,$CLJS.Wr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Wr,$CLJS.pG,$CLJS.U($CLJS.Pj,TN),dR,$CLJS.U($CLJS.Pj,TN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:bP}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&
"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(VP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",13,$CLJS.as,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.as,$CLJS.pG,$CLJS.U($CLJS.Pj,eS),dR,$CLJS.U($CLJS.Pj,eS),HQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,eS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:WO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(iN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,
$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EN,"metabase/mbql/schema.cljc",14,$CLJS.HG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HG,$CLJS.pG,$CLJS.U($CLJS.Pj,eS),dR,$CLJS.U($CLJS.Pj,eS),HQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,eS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(fU)?fU.H:null])):null));
return $CLJS.n(a)?a:EN}(),fU],null)])));
$CLJS.X(OP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"valid filter expression",$CLJS.Ni,function(a){return $CLJS.n(iM(cT,a))?$CLJS.hJ:$CLJS.n(iM($S,a))?nS:$CLJS.n(iM(XS,a))?$CLJS.Yj:$CLJS.n(iM(aT,a))?$CLJS.Ns:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,$CLJS.fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[nS,dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ns,eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,kM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(sR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",
15,$CLJS.Es,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,RN,$CLJS.U($CLJS.Pj,fP),JP,$CLJS.U($CLJS.Pj,fP),aO,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,fP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:aP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(NP,new $CLJS.h(null,1,[$CLJS.ak,
!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",14,$CLJS.xs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,RN,$CLJS.U($CLJS.Pj,fP),JP,$CLJS.U($CLJS.Pj,fP),aO,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,fP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n($T)?
$T.H:null])):null));return $CLJS.n(a)?a:QP}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(AQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sP,"metabase/mbql/schema.cljc",15,$CLJS.zs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.gS,$CLJS.U($CLJS.Pj,fP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:sP}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(VP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",13,$CLJS.as,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.as,$CLJS.pG,$CLJS.U($CLJS.Pj,eS),dR,$CLJS.U($CLJS.Pj,eS),HQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,eS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(eU)?eU.H:null])):
null));return $CLJS.n(a)?a:WO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(iN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EN,"metabase/mbql/schema.cljc",14,$CLJS.HG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HG,$CLJS.pG,$CLJS.U($CLJS.Pj,eS),dR,$CLJS.U($CLJS.Pj,eS),HQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,eS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:EN}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},
$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",13,$CLJS.Yr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yr,$CLJS.pG,$CLJS.U($CLJS.Pj,TN),dR,$CLJS.U($CLJS.Pj,TN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:UO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ur,$CLJS.pG,$CLJS.U($CLJS.Pj,TN),dR,$CLJS.U($CLJS.Pj,TN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:VO}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od($R,new $CLJS.h(null,
1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",14,$CLJS.$r,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$r,$CLJS.pG,$CLJS.U($CLJS.Pj,TN),dR,$CLJS.U($CLJS.Pj,TN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(iU)?iU.H:null])):
null));return $CLJS.n(a)?a:TO}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(SR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bP,"metabase/mbql/schema.cljc",14,$CLJS.Wr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Wr,$CLJS.pG,$CLJS.U($CLJS.Pj,TN),dR,$CLJS.U($CLJS.Pj,TN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:bP}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(eja,new $CLJS.h(null,
1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kN,"metabase/mbql/schema.cljc",19,$CLJS.SF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.SF,$CLJS.pG,$CLJS.U($CLJS.Pj,TN),$CLJS.Zn,$CLJS.U($CLJS.Pj,TN),$CLJS.ik,$CLJS.U($CLJS.Pj,TN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:kN}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iR,"metabase/mbql/schema.cljc",23,$CLJS.gG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gG,$CLJS.pG,$CLJS.U($CLJS.Pj,cR),DN,$CLJS.U($CLJS.Pj,cR),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,yQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:iR}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},
$CLJS.od(hia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",21,$CLJS.AG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AG,$CLJS.pG,$CLJS.U($CLJS.Pj,cR),DN,$CLJS.U($CLJS.Pj,cR),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,yQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:IN}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(uia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",20,$CLJS.oG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oG,$CLJS.pG,$CLJS.U($CLJS.Pj,cR),DN,$CLJS.U($CLJS.Pj,cR),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,yQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:UN}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==
typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yM,"metabase/mbql/schema.cljc",36,$CLJS.hG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.hG,$CLJS.pG,$CLJS.U($CLJS.Pj,cR),DN,$CLJS.U($CLJS.Pj,cR),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,yQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:yM}(),uU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(Ija,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),
$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gR,"metabase/mbql/schema.cljc",26,$CLJS.YF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.YF,Qha,$CLJS.U($CLJS.Pj,TN),dka,$CLJS.U($CLJS.Pj,TN),Pja,$CLJS.U($CLJS.Pj,TN),Tha,$CLJS.U($CLJS.Pj,TN),Jja,$CLJS.U($CLJS.Pj,TN),Pha,$CLJS.U($CLJS.Pj,
TN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:gR}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(via,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",28,$CLJS.TF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,$CLJS.pG,$CLJS.U($CLJS.Pj,MN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:BP}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&
"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(tja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",29,$CLJS.$F,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,$CLJS.pG,$CLJS.U($CLJS.Pj,MN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:pQ}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(Uja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$N,"metabase/mbql/schema.cljc",27,$CLJS.RF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.pG,$CLJS.U($CLJS.Pj,MN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:$N}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&
"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(Hia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pN,"metabase/mbql/schema.cljc",28,$CLJS.bG,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,$CLJS.pG,$CLJS.U($CLJS.Pj,MN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:pN}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(Hha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OQ,"metabase/mbql/schema.cljc",33,$CLJS.BG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.BG,$CLJS.pG,$CLJS.U($CLJS.Pj,MN),$CLJS.rk,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.Js,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mk,$CLJS.Zv,$CLJS.ay,$CLJS.XF],null)],null)),$CLJS.ri,$CLJS.U($CLJS.Pj,$O),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,jja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:OQ}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(Pia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QR,"metabase/mbql/schema.cljc",27,$CLJS.OF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,Xha,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,zja,bN],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:QR}(),wU],null)]))],null)],null));
$CLJS.X(BQ,jM($CLJS.ZF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.xt,":case subclause"],null),$CLJS.YT,jT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),jT],null)],null)],null)])));
var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);
$CLJS.X(hR,kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(YP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MO,"metabase/mbql/schema.cljc",51,$CLJS.cs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cs,$CLJS.HE,$CLJS.U($CLJS.Pj,bQ),zR,$CLJS.U($CLJS.Pj,bQ),QN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,bQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:MO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},
$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",51,$CLJS.qt,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qt,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ),
zR,$CLJS.U($CLJS.Pj,bQ),QN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,bQ))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:NO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM?new $CLJS.Gc(function(){return yT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,pP,$CLJS.Si,$CLJS.mj,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.jA,"metabase/mbql/schema.cljc",$CLJS.LG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LG,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ),zR,$CLJS.U($CLJS.Pj,YQ),QN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,YQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.jA}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(bja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LO,"metabase/mbql/schema.cljc",51,$CLJS.ds,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ds,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ),zR,$CLJS.U($CLJS.Pj,YQ),QN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,YQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:LO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},
$CLJS.od(LQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",58,$CLJS.sG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sG,IR,$CLJS.U($CLJS.Pj,DM),HR,$CLJS.U($CLJS.Pj,
DM),QN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,DM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:$Q}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,
$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",56,$CLJS.Vx,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vx,$CLJS.ry,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:sQ}(),nT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mP,"metabase/mbql/schema.cljc",55,$CLJS.nG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nG,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:mP}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),
$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aN,"metabase/mbql/schema.cljc",54,$CLJS.LF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(BT)?
BT.H:null])):null));return $CLJS.n(a)?a:aN}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(ria,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",55,$CLJS.uG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:cP}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&
"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od($ja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",53,$CLJS.QF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.QF,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:FP}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(Nja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,
lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[WR,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",69,$CLJS.NF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.NF,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ),zR,$CLJS.U($CLJS.Pj,YQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:rR}(),ET],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[WR,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HO,"metabase/mbql/schema.cljc",68,$CLJS.cG,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:HO}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),
$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[WR,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",67,$CLJS.HF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(GT)?
GT.H:null])):null));return $CLJS.n(a)?a:eP}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(Wha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[WR,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bS,"metabase/mbql/schema.cljc",67,$CLJS.NG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,$CLJS.HE,$CLJS.U($CLJS.Pj,YQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:bS}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&
"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(OM,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",61,$CLJS.ZF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.ZF,$CLJS.hx,$CLJS.U($CLJS.Pj,fO),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,PP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:wP}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Zha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,
$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",65,$CLJS.dJ,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dJ,lia,$CLJS.U($CLJS.Pj,mS),mia,$CLJS.U($CLJS.Pj,mS),$CLJS.ri,$CLJS.U($CLJS.Pj,Sia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:GP}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(wja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(WQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",71,$CLJS.sJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sJ,$CLJS.hJ,$CLJS.U($CLJS.Pj,mS),$CLJS.ri,$CLJS.U($CLJS.Pj,Tja),$CLJS.xi,$CLJS.U($CLJS.Pj,$CLJS.U(oR,uS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:ZP}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(Dia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xN,"metabase/mbql/schema.cljc",71,$CLJS.$I,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$I,$CLJS.IP,$CLJS.U($CLJS.Pj,mS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:xN}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(sia,new $CLJS.h(null,1,[$CLJS.ak,!0],
null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",74,$CLJS.wJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wJ,$CLJS.IP,$CLJS.U($CLJS.Pj,mS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:XP}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Zia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),
$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uQ,"metabase/mbql/schema.cljc",72,$CLJS.TI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TI,$CLJS.IP,$CLJS.U($CLJS.Pj,mS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:uQ}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==
typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",
71,$CLJS.mJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mJ,$CLJS.IP,$CLJS.U($CLJS.Pj,mS),$CLJS.xi,$CLJS.U($CLJS.Pj,$CLJS.U(oR,uS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:tQ}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.ak,
!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",70,$CLJS.lJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lJ,$CLJS.IP,$CLJS.U($CLJS.Pj,mS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:mQ}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),
$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cQ,"metabase/mbql/schema.cljc",78,$CLJS.qJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qJ,$CLJS.IP,$CLJS.U($CLJS.Pj,mS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:cQ}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==
typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(Ria,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EM,"metabase/mbql/schema.cljc",
71,$CLJS.tJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tJ,$CLJS.hJ,$CLJS.U($CLJS.Pj,mS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:EM}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Aja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,
$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fS,"metabase/mbql/schema.cljc",73,$CLJS.pJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pJ,$CLJS.hJ,$CLJS.U($CLJS.Pj,mS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(RT)?RT.H:null])):null));
return $CLJS.n(a)?a:fS}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,
[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",73,$CLJS.ZI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZI,$CLJS.hJ,$CLJS.U($CLJS.Pj,mS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:yP}(),ST],null)])));
$CLJS.X(TM,kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},$CLJS.od(qja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CQ,"metabase/mbql/schema.cljc",59,$CLJS.IF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IF,$CLJS.ry,$CLJS.U($CLJS.Pj,cR),$CLJS.Ev,$CLJS.U($CLJS.Pj,oia),$CLJS.Vx,$CLJS.U($CLJS.Pj,$CLJS.U(oR,YQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:CQ}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},
$CLJS.od(Qja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZQ,"metabase/mbql/schema.cljc",54,$CLJS.GG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,$CLJS.ry,$CLJS.U($CLJS.Pj,cR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:ZQ}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IQ,"metabase/mbql/schema.cljc",55,$CLJS.IG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IG,$CLJS.ry,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:IQ}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&
"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(rja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",
55,$CLJS.PG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PG,$CLJS.ry,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:KP}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(Iha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,
$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KR,"metabase/mbql/schema.cljc",57,$CLJS.OG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OG,$CLJS.ry,$CLJS.U($CLJS.Pj,cR),Uha,$CLJS.U($CLJS.Pj,$CLJS.Yj),$CLJS.qA,$CLJS.U($CLJS.Pj,$CLJS.Yj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:KR}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pS,"metabase/mbql/schema.cljc",55,$CLJS.Qt,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qt,$CLJS.ry,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:pS}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==
typeof oM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lR,"metabase/mbql/schema.cljc",55,$CLJS.Kt,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kt,$CLJS.ry,$CLJS.U($CLJS.Pj,cR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:lR}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(ija,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,
$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yO,"metabase/mbql/schema.cljc",56,$CLJS.PF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PF,IR,$CLJS.U($CLJS.Pj,cR),HR,$CLJS.U($CLJS.Pj,cR),QN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,cR))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:yO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.fQ,"null",$CLJS.Oda,"null"],
null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vN,"metabase/mbql/schema.cljc",74,$CLJS.rG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rG,$CLJS.ry,$CLJS.U($CLJS.Pj,cR),$CLJS.pA,$CLJS.U($CLJS.Pj,$CLJS.Yj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:vN}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==
typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(LQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fQ,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
$Q,"metabase/mbql/schema.cljc",58,$CLJS.sG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sG,IR,$CLJS.U($CLJS.Pj,DM),HR,$CLJS.U($CLJS.Pj,DM),QN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,DM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:$Q}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},
$CLJS.od(OM,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",61,$CLJS.ZF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZF,$CLJS.hx,$CLJS.U($CLJS.Pj,fO),
$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,PP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:wP}(),xU],null)])));
$CLJS.yU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,":field or :expression reference or expression",$CLJS.Ni,function(a){return $CLJS.n(iM($S,a))?nS:$CLJS.n(iM(XS,a))?$CLJS.Yj:$CLJS.n(iM(aT,a))?$CLJS.Ns:$CLJS.n(iM(cT,a))?$CLJS.hJ:$CLJS.n(iM($CLJS.ZF,a))?$CLJS.ZF:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[nS,dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ns,eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,$CLJS.fT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.US],null)],null);$CLJS.X(TR,jM($CLJS.Xx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.US],null)])));var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TR],null);$CLJS.X(HP,jM($CLJS.qG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.US],null)])));var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HP],null);$CLJS.X(RM,jM($CLJS.jG,$CLJS.H(["field-or-expression",$CLJS.yU])));
var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);$CLJS.X(LR,jM($CLJS.MG,$CLJS.H(["field-or-expression",$CLJS.yU])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null);$CLJS.X(qO,jM($CLJS.VF,$CLJS.H(["field-or-expression",$CLJS.yU])));var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qO],null);$CLJS.X(aR,jM($CLJS.CG,$CLJS.H(["field-or-expression",$CLJS.yU])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aR],null);$CLJS.X(lO,jM($CLJS.Zn,$CLJS.H(["field-or-expression",$CLJS.yU])));
var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null);$CLJS.X(YN,jM($CLJS.ik,$CLJS.H(["field-or-expression",$CLJS.yU])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YN],null);$CLJS.X(SN,jM($CLJS.KG,$CLJS.H(["field-or-expression",$CLJS.yU,"pred",$CLJS.YT])));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SN],null);$CLJS.X(iO,jM($CLJS.yG,$CLJS.H(["pred",$CLJS.YT])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iO],null);$CLJS.X(SQ,jM($CLJS.wG,$CLJS.H(["pred",$CLJS.YT])));
var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SQ],null);$CLJS.X(oN,jM($CLJS.FG,$CLJS.H(["field-or-expression",$CLJS.yU])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null);$CLJS.X(qM,jM($CLJS.WF,$CLJS.H(["field-or-expression",$CLJS.yU])));var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null);$CLJS.X(uO,jM($CLJS.DG,$CLJS.H(["field-or-expression",$CLJS.yU])));var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uO],null);$CLJS.X(hP,jM($CLJS.aG,$CLJS.H(["field-or-expression",$CLJS.yU,"percentile",hT])));
var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hP],null);$CLJS.X(VQ,jM($CLJS.kG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TK],null),vS],null)])));var OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);
$CLJS.X(RQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"unnamed aggregation clause or numeric expression",$CLJS.Ni,function(a){return $CLJS.n(iM($S,a))?kP:$CLJS.Mt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[kP,dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(oja,
new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",60,$CLJS.jG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,mR,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:tP}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(zia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yR,"metabase/mbql/schema.cljc",64,$CLJS.MG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,mR,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:yR}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==
typeof oM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(qia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",65,$CLJS.VF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,mR,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:xP}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(Kja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,
$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.zG,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LM,"metabase/mbql/schema.cljc",76,$CLJS.FG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,mR,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:LM}(),KU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(dja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gN,"metabase/mbql/schema.cljc",60,$CLJS.CG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,mR,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:gN}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(Rha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,
$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.dt,"metabase/mbql/schema.cljc",60,$CLJS.Zn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zn,mR,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(FU)?FU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.dt}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(Mha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ht,"metabase/mbql/schema.cljc",60,$CLJS.ik,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,mR,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:$CLJS.ht}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&
"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.od(nia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",18,$CLJS.kG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,Aia,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.xs,Eia,bN],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:xO}(),OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(Mia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,
$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",62,$CLJS.wG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,$CLJS.ro,$CLJS.U($CLJS.Pj,fP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:pO}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(Sha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cN,"metabase/mbql/schema.cljc",68,$CLJS.yG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yG,$CLJS.ro,$CLJS.U($CLJS.Pj,fP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:cN}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.ak,!0],
null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",66,$CLJS.KG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.KG,mR,$CLJS.U($CLJS.Pj,eR),$CLJS.ro,$CLJS.U($CLJS.Pj,fP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:uP}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(OM,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",61,$CLJS.ZF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZF,$CLJS.hx,$CLJS.U($CLJS.Pj,fO),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(oR,PP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:wP}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&
"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lG,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wR,"metabase/mbql/schema.cljc",
68,$CLJS.DG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,mR,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:wR}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,
pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lG,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",72,$CLJS.aG,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aG,mR,$CLJS.U($CLJS.Pj,eR),$CLJS.aG,$CLJS.U($CLJS.Pj,YQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(NU)?NU.H:null])):
null));return $CLJS.n(a)?a:UQ}(),NU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(bia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GM,"metabase/mbql/schema.cljc",77,$CLJS.WF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,mR,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:GM}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,
$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",74,$CLJS.qG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,$CLJS.pG,$CLJS.U($CLJS.Pj,$CLJS.U(oR,MN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:gP}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([nP,$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,qR,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KF,"null"],null),null),
$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.wi,"metabase/mbql/schema.cljc",70,$CLJS.Xx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xx,$CLJS.pG,$CLJS.U($CLJS.Pj,$CLJS.U(oR,MN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:$CLJS.wi}(),zU],null)]))],null)],null));
$CLJS.X(iQ,jM($CLJS.BM,$CLJS.H(["aggregation",RQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.xt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),vS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),vS],null)],null)])));
$CLJS.X(nR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.xt,"aggregation clause or numeric expression",$CLJS.Ni,function(a){return $CLJS.n(iM($CLJS.BM,a))?$CLJS.BM:eO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[eO,RQ],null)],null));$CLJS.X(KM,jM($CLJS.mG,$CLJS.H(["field",WS])));var PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KM],null);$CLJS.X(jP,jM($CLJS.fG,$CLJS.H(["field",WS])));
QU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jP],null);
pka=kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof PU?new $CLJS.Gc(function(){return PU},$CLJS.od(Hja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
QO,"metabase/mbql/schema.cljc",15,$CLJS.mG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,$CLJS.pG,$CLJS.U($CLJS.Pj,GR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(PU)?PU.H:null])):null));return $CLJS.n(a)?a:QO}(),PU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof QU?new $CLJS.Gc(function(){return QU},$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",16,$CLJS.fG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,$CLJS.pG,$CLJS.U($CLJS.Pj,GR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:MQ}(),QU],null)]));
RU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mk,$CLJS.NM,$CLJS.CN,$CLJS.JF,$CLJS.Zt,$CLJS.BB,$CLJS.IP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uE,vS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),vS],null)],null);
qka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,RU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.NM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zM,vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$P,yS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),yS],null)],null)],null);
rka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,RU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.CN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jR,yS],null)],null)],null);
SU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,RU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null)],null)],null);ska=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xR],null);
tka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,SU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.JF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Wi,$CLJS.Os],null)],null)],null)],null);
$CLJS.TU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.IP,null,$CLJS.Zt,null,$CLJS.Ns,null,$CLJS.BB,null],null),null);uka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mk],null),$CLJS.TU);
$CLJS.X(FQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.lj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,tka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NM,qka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CN,rka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,SU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,uka],null)],null)],null)],null)],null));
var UU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,vS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,vS],null)],null)],null),vka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,UU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.Os],null)],null)],null),VU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null),WU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Ni,function(a){return $CLJS.n($CLJS.nE($CLJS.xd,
$CLJS.qQ)(a))?$CLJS.qQ:$CLJS.tE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wt,UU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qQ,$CLJS.Os],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tE,VU],null)],null),XU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kD,wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[fja,vS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.KO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.hk],null)],null)],null),YU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,hka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.xt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),wka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mk],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.oF,null,$CLJS.YE,null,$CLJS.hF,null,$CLJS.lF,null],null),null)),ZU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vR],null);
$CLJS.X(oO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),YU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),WU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YR,$CLJS.YT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),wka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,$CLJS.Cy,$CLJS.kA],null),ZU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),vS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YO,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,XU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.nE($CLJS.FA($CLJS.lP,$CLJS.PQ),$CLJS.Te($CLJS.nE($CLJS.lP,$CLJS.PQ)))],null)],null));var xka=$CLJS.Q,$U;var aV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oO],null)],null);
if($CLJS.wd(aV)&&$CLJS.E.g($CLJS.A(aV),$CLJS.yj)){var bV=$CLJS.y(aV);$CLJS.A(bV);var cV=$CLJS.B(bV),yka=$CLJS.xd($CLJS.A(cV))?cV:$CLJS.ee(null,cV),dV=$CLJS.y(yka),zka=$CLJS.A(dV),Aka=$CLJS.B(dV);$U=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.R.j(zka,$CLJS.Zn,1)],null),Aka)}else $U=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,aV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(qN,new $CLJS.P(null,3,5,xka,[$CLJS.Es,$U,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"All join aliases must be unique."],null),function(a){return $CLJS.VL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.cF,a)))}],null)],null));var Bka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null);
$CLJS.X(vR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ql,new $CLJS.h(null,1,[$CLJS.xt,"Distinct, non-empty sequence of Field clauses"],null),lM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.US],null))],null));
$CLJS.X(nQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),WU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),YU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.gT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,new $CLJS.h(null,1,
[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.US],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,vS,$CLJS.yU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),ZU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.YT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sS,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),zS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),lM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),pka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[rQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[rQ,yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[gia,yS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),Bka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,XU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.em(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PQ,$CLJS.lP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,
new $CLJS.h(null,1,[$CLJS.xt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.dG);b=$CLJS.J.g(b,$CLJS.ZE);return $CLJS.td($CLJS.mu.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.eV=$CLJS.Ig([$CLJS.JG,$CLJS.IP,kO,WN,ZO,jS,FO,$CLJS.iS,$CLJS.Zt,EO,UM,bR,ZR,$CLJS.jN,UR,hO,$CLJS.dP,CO,$CLJS.Yi,$CLJS.BO,CM,$CLJS.GN,$CLJS.AO,DP,$CLJS.Ns,$CLJS.BB,wO],[new $CLJS.h(null,1,[PO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.JG,null,$CLJS.IP,null,$CLJS.Zt,null,$CLJS.Ns,null,$CLJS.BB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.IP,PO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.JG,null,$CLJS.IP,null,$CLJS.iS,null,$CLJS.dP,null,$CLJS.Yi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.lj,$CLJS.Yj,$CLJS.CE,kR,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[kO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,nS,$CLJS.CE,kR,PO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.JG,null,WN,null,$CLJS.Zt,null,$CLJS.Yi,null,CM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Yj,$CLJS.CE,YM,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[ZO,null],null),null)],null),new $CLJS.h(null,1,[PO,new $CLJS.ah(null,new $CLJS.h(null,1,[jS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,
nS,$CLJS.CE,xja,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[FO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.IP,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.iS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,nS,PO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.JG,null,WN,null,$CLJS.Zt,null,$CLJS.Yi,null,CM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,nS,$CLJS.CE,YM,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[EO,null],null),null)],null),new $CLJS.h(null,1,[PO,new $CLJS.ah(null,
new $CLJS.h(null,1,[UM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Yj,$CLJS.CE,kR,PO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.JG,null,jS,null,UM,null,bR,null,CO,null,$CLJS.Yi,null,CM,null,$CLJS.BB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Yj,$CLJS.CE,YM,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[ZR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.IP,PO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.iS,null,$CLJS.jN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.lj,$CLJS.Yj,$CLJS.CE,YM,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[UR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Yj,$CLJS.CE,YM,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[hO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.IP,PO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.JG,null,$CLJS.IP,null,$CLJS.iS,null,$CLJS.dP,null,$CLJS.Yi,null],null),null)],null),new $CLJS.h(null,1,[PO,new $CLJS.ah(null,new $CLJS.h(null,1,[CO,null],null),null)],null),new $CLJS.h(null,1,[PO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.Yi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.IP,PO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.iS,null,$CLJS.BO,null],null),null)],null),new $CLJS.h(null,1,[PO,new $CLJS.ah(null,new $CLJS.h(null,1,[CM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.IP,PO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.iS,null,$CLJS.GN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.IP,PO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.iS,
null,$CLJS.AO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,nS,$CLJS.CE,kR,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[DP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.Ns,PO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.JG,null,$CLJS.Yi,null,$CLJS.Ns,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.Yj,PO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.JG,null,jS,null,UM,null,bR,null,CO,null,$CLJS.Yi,null,CM,null,$CLJS.BB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,
nS,$CLJS.CE,YM,PO,new $CLJS.ah(null,new $CLJS.h(null,1,[wO,null],null),null)],null)]);$CLJS.X(xR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.eV)));$CLJS.X(fN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.xt,"valid template tag widget type"],null),$CLJS.kA],null),$CLJS.Xg($CLJS.eV)));
$CLJS.X(KQ,jM(Yha,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,vS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,vS],null)],null)],null)])));var fV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KQ],null);$CLJS.X(FM,jM($CLJS.JF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.US,fV],null)])));var gV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FM],null);$CLJS.X(AN,jM(zO,$CLJS.H(["target",fV])));
var hV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AN],null),Cka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.US,kM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof gV?new $CLJS.Gc(function(){return gV},$CLJS.od(Wia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vQ,"metabase/mbql/schema.cljc",21,$CLJS.JF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,$CLJS.zJ,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,MN,OO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(gV)?gV.H:null])):null));return $CLJS.n(a)?a:vQ}(),gV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=pP.h($CLJS.pd("undefined"!==typeof $CLJS.mM&&
"undefined"!==typeof nM&&"undefined"!==typeof oM&&"undefined"!==typeof hV?new $CLJS.Gc(function(){return hV},$CLJS.od(lja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.pk,$CLJS.ui,pP,$CLJS.Si,$CLJS.mj,$CLJS.yk,lN,$CLJS.ti,$CLJS.jk,$CLJS.ok],[$CLJS.od(WQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oS,"metabase/mbql/schema.cljc",20,zO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[zO,
$CLJS.zJ,$CLJS.U($CLJS.Pj,OO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(hV)?hV.H:null])):null));return $CLJS.n(a)?a:oS}(),hV],null)]))],null),fM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);
$CLJS.X(mN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.nE($CLJS.FA($CLJS.qQ,$CLJS.MF),$CLJS.Te($CLJS.nE($CLJS.qQ,$CLJS.MF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.qQ);var d=$CLJS.J.g(c,$CLJS.MF);c=$CLJS.J.g(c,$CLJS.lj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.xt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.cL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,$CLJS.MF,$CLJS.qQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qQ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),vka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),VU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,ska],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),vS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zJ,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),Cka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),vS],null),new $CLJS.P(null,3,5,$CLJS.Q,[dia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),
vS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Os],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fL,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[Wja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),IS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Via,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[vP,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),zS],null),new $CLJS.P(null,3,5,$CLJS.Q,[eN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,vP);b=$CLJS.J.g(b,eN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[Gja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[$ha,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jha,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[fia,new $CLJS.h(null,1,[$CLJS.Ss,
!0],null),$CLJS.Ns],null),new $CLJS.P(null,3,5,$CLJS.Q,[yja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Ns],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lha,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Ns],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Ns],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.My,new $CLJS.h(null,
1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wN,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.mk,kja,hja,$CLJS.sO,jia,fka,mja,Bia,Xia,tia,aia,xia,vja,Jia,Vha,kia,eka,bka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Bja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,yS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Gia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jR,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,gka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Iia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,yS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[Vja,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.Os,$CLJS.Os],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Cia,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vO,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Cs,$CLJS.Os,$CLJS.Os],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RK,new $CLJS.h(null,1,[$CLJS.Ss,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.Os],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.xt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.YO)],null)],null));var iV=$CLJS.eM(fM);
(function(){var a=Eha();return function(b){if($CLJS.n(iV.h?iV.h(b):iV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.bM(b);throw $CLJS.hi($CLJS.pE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Iy,c,Qia,b],null));}})();